@charset "UTF-8";
@import "ap_preaset";

/****************
*** 조회/체크인 ***
****************/


/* 예약 조회 */
.findTripWrap .findTripCon {
    padding: toRem(50);
    border: toRem(2) solid $ap-line-black;
    border-radius: toRem(12);
}
.findTripWrap .findTripCon .inputWrap {
    margin-top: toRem(60);
}
.findTripWrap .findTripCon .TripBtnWrap {
    width: toRem(300);
    height: toRem(50);
    margin: toRem(50) auto 0;
}

/* 출도착 조회 */
.dacWrap .secSlt {
	width: calc(100% - #{toRem(160)});
	height: toRem(50);
	padding: 0 toRem(20);
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: toRem(1) solid $ap-line-black;
	border-radius: toRem(8);
}
.dacWrap .secSlt.noSrch {
	margin-right: toRem(20);
}
.dacWrap .secSlt.noSrch .slctDatebx {
	width: 100%;
}
.dacWrap .secSlt .slctBox .slctCon {
	width: 100%;
}
.dacWrap .secSlt .slctBox.slctDatebx .slctCon {
	width: 100%;
	min-width: toRem(400);
}
.dacWrap .secSlt .arrBar {
	width: toRem(1);
	height: toRem(20);
	background: $ap-line-gray;
}
.dacWrap .taskButton {
	width: toRem(150);
}
.dacWrap .checkRes {
	margin-top: toRem(100);
}
.dacWrap .checkRes.none {
	height: toRem(200);
	line-height: toRem(200);
	text-align: center;
	color: $ap-text-gray-1;
	border-bottom: toRem(1) solid $ap-line-gray;
}
.dacWrap .cautionWrap {
	margin-top: toRem(80);
}
.dacWrap .checkRes .dateBtnWrap {
	display: flex;
	justify-content: space-between;
	margin-bottom: toRem(60);
}
.dacWrap .checkRes .dateBtnWrap button {
	padding: toRem(15) toRem(30);
	font-weight: 700;
	border: toRem(1) solid transparent;
	border-radius: toRem(28);
}
.dacWrap .checkRes .dateBtnWrap .on {
	color: $ap-solid-orange;
	border: toRem(1) solid $ap-solid-orange;
}
.dacWrap .checkRes .subSmTit {
	margin-bottom: toRem(40);
}
.dacWrap .checkRes .upCri {
	margin-bottom: toRem(20);
	color: $ap-text-gray-1;
}
.dacWrap .checkRes .lineTb thead {
	font-weight: 300;
}
.dacWrap .checkRes .lineTb tbody td {
	padding: toRem(25) toRem(20) toRem(25) 0;
}
.dacWrap .checkRes .lineTb td {
	text-align: left;
}
.dacWrap .checkRes .lineTb td:last-child {
	text-align: right;
	letter-spacing: -0.9;
	padding-right: 0;
}
.dacWrap .checkRes .lineTb .skedWrap {
	display: flex;
	align-items: center;
}
.dacWrap .checkRes .lineTb .skedWrap p {
	margin-right: toRem(10);
	color: $ap-text-gray-1;
}
.dacWrap .checkRes .lineTb .skedBar {
	width: 100%;
	height: toRem(1);
	margin: toRem(15) 0;
	background: $ap-text-gray-3;
}
.dacWrap .sectionChk .secSlt .slctBox {
	width: 45%;
}
.dacWrap .sectionChk .secSlt.slctThr .slctBox {
	width: 30%;
}
.dacWrap .fNumChk .secSlt .fName {
	width: toRem(150);
}
.dacWrap .fNumChk .secSlt .fNum {
	width: calc(100% - #{toRem(200)});
}
.dacWrap .realTimeChk .secSlt .asOf {
	width: toRem(150);
}
.dacWrap .realTimeChk .secSlt .slctBox {
	width: calc(100% - #{toRem(200)});
}


.dacWrap .tabBtn {
	margin-bottom: toRem(40);
	border-bottom: 1px solid var(--Border-Neutral-border2, #EDF0F5);

	&.mbn {
		padding: 0 toRem(30);
		margin-bottom: toRem(30);
	}

	.btn {
		height: 52px;
		margin-right: toRem(30);
		position: relative;
		color: var(--On-View-onView3, #6D7788);
		font-weight: 500;
		margin-bottom: 0;

		&:last-child {
			margin-right: 0;
		}

		&.on {
			font-weight: 700;
			color: var(--On-View-onView2, #333D4B);
			&:after {
				content: '';
				width: 100%;
				height: toRem(2);
				display: block;
				position: absolute;
				bottom: -1px;
				left: 0;
				background: var(--On-View-onView2, #333D4B);
			}
		}
	}
}

/* 탑승 수속 */
#checkInLayout > .comContent > h2.subMdTit
{
	line-height: 36px;
}

#checkInLayout .listGlobal > li,
#checkInLayout .bodStp .stpValue{
	line-height: 24px;
}


@include rwd('sm') {
	#checkInLayout > .comContent > h2.subMdTit, #checkInLayout .listGlobal > li,
	#checkInLayout .bodStp .stpValue{
		line-height: 1.6em;
	}
}

@include rwd('md'){
	.dacWrap .fNumChk .secSlt .fName {
		width: toRem(100);
	}
	.dacWrap .fNumChk .secSlt .fNum {
		width: calc(100% - #{toRem(120)});
	}
}
@include rwd('sm'){
	/* 예약 조회 */
	.findTripWrap {
	    margin: toRem(40) 0 toRem(30);
	}
	.findTripWrap .findTripCon {
	    padding: toRem(25);
	}
	.findTripWrap .subSmTit {
	    font-size: toRem(18);
	    font-weight: 500;
	}
	.findTripWrap .findTripCon .inputWrap {
	    margin-top: toRem(30);
	}
	.chkComTit {
	    margin-bottom: 0;
	}
	.heloIlust .comTitle {
	    margin-bottom: 0;
	}
	.heloIlust .rud .tabBtn {
	    margin-top: toRem(40);
	    margin-bottom: 0;
	}
	.ucmTb {
	    margin-top: toRem(20) !important;
	    margin-bottom: toRem(40);
	}
	.heloIlust .paging {
	    padding-bottom: toRem(50);
	}
	.heloIlust .lineTb thead {
	    display: none;
	}
	.heloIlust .lineTb tbody {
	    border-top: toRem(2) solid $ap-line-black;
	}

	/* 출도착 조회 */
	.dacWrap .flex.sltFlex {
		display: block;
	}
	.chkPage .dacWrap .secSlt {

	}
	.chkPage .dacWrap .secSlt .slctBox {
		width: 100%;
		padding: toRem(15) 0;
		display: block;
		border-bottom: toRem(1) solid $ap-line-gray;
	}
	.chkPage .dacWrap .secSlt .slctCon {
		top: toRem(50);
	}
	.chkPage .dacWrap .secSlt .arrBar {
		display: none;
	}
	.chkPage .dacWrap .secSlt.noSrch .arrBar {
		display: block;
	}
	.chkPage .dacWrap .secSlt .taskButton {
		width: 100%;
		margin-top: toRem(30);
	}
	.chkPage .dacWrap .secSlt.noSrch.noflt {
		padding: toRem(15) 0;
		display: flex;
		justify-content: space-between;
		border-bottom: toRem(1) solid $ap-text-gray-4;
		border-radius: 0;
	}
	.chkPage .dacWrap .secSlt.noSrch::last-child {
		margin-top: toRem(20);
	}
	.chkPage .dacWrap .realTimeChk .secSlt {
		width: 100%;
		padding: 0 toRem(20);
		display: flex;
		border: toRem(1) solid $ap-line-black;
	}
	.chkPage .dacWrap .realTimeChk .arrBar {
		display: block;
	}
	.dacWrap .secSlt {
		width: 100%;
	}
	.dacWrap .sectionChk .secSlt.slctThr .slctBox {
		width: 100%;
	}
	.dacWrap .fNumChk .secSlt .fName {
		width: toRem(60);
	}
	.dacWrap .fNumChk .secSlt .fNum {
		width: calc(100% - #{toRem(100)});
	}
	.dacWrap .secSlt.slctThr .slctBox .slctCon {
		min-width: auto;
	}
	.dacWrap .taskButton {
		width: 100%;
		margin-top: toRem(20);
	}
	.dacWrap .checkRes .dateBtnWrap {
		flex-wrap: wrap;
		justify-content: flex-start;
	}
	.dacWrap .checkRes .dateBtnWrap button {
		width: 50%;
	    height: auto;
	    padding: toRem(5) toRem(10);
	    font-size: 1.4rem;
	}
	.dacWrap .checkRes .dateBtnWrap .on {
		color: $ap-white;
	    border: toRem(1) solid $ap-solid-orange;
	    background: $ap-solid-orange;
	}
	.dacWrap .checkRes .lineTb tr td {
		padding: toRem(15) toRem(10) toRem(15) 0;
		vertical-align: middle;
		font-size: toRem(14);
	}
	.dacWrap .checkRes .lineTb .skedWrap {
		display: block;
	}
	.dacWrap .checkRes .lineTb .skedWrap p {
		margin-right: 0;
	}
	.dacWrap .realTimeChk .secSlt .asOf {
		width: toRem(80);
	}
	.dacWrap .realTimeChk .secSlt .slctBox {
		width: calc(100% - #{toRem(100)});
	}
}

@include rwd('xs'){
	/* 예약 조회 */
	.findTripWrap .findTripCon .TripBtnWrap {
	    width: 100%;
	}

	/* 출도착 조회 */
	.dacWrap .checkRes .lineTb tr td .badge {
		width: toRem(50);
		text-align: center;
		display: block;
	}
}


.dacWrap {
	.tabCont {
		.cont.on {
			display: flex;
			gap: 48px;

			.aside {
				padding-top: 20px;
				width: 375px;
				.sltFlex {
					display: flex;
					flex-direction: column;
					gap: 12px;
				}
				.secSlt {
					width: 100%;
					height: auto;
					border: 0 none;
					padding: 0;
					gap: 12px;
					justify-content: flex-start;

					&.direction-column{
						flex-direction: column;
						justify-content: stretch;
						align-items: stretch;
					}
					.slctBox {
						width: 100%;
						padding: 0;
						border-bottom: 1px solid var(--Border-Neutral-border3, #E6EAF0);
						display: flex;
						height: 46px;
						min-width: 180px;
						justify-content: space-between;
						align-items: center;
						align-self: stretch;
						gap: 10px;

						&.on button.val>i.icon{
							transform: none;
						}

						button.val {
							color: var(--On-View-onView4, #9AA2B1);
							font-size: 16px;
							font-style: normal;
							font-weight: 500;
							line-height: 24px; /* 150% */
							gap: 8px;

							> span {
								flex: 1;
								text-align: left;
							}

							.slct {
								color: #333D4B;
							}
						}


						i.arrow-down {
							width: 20px;
							height: 20px;
							overflow: hidden;
							text-indent: -9999px;
							background: url(https://com.airpremiacdn.net/resources/onepoint/images/depArrCheck/Dropdown.svg) no-repeat center;
							transition: .2s;
						}

						i.icon {
							width: 20px;
							height: 20px;
							overflow: hidden;
							text-indent: -9999px;
							display: inline-block;
							margin-left: 0;
						}

						.icon-FlightTakeoff {
							background: url(https://com.airpremiacdn.net/resources/onepoint/images/depArrCheck/FlightTakeoff.png) no-repeat center;
							background-size: 20px;
						}
						.icon-FlightLand {
							background: url(https://com.airpremiacdn.net/resources/onepoint/images/depArrCheck/FlightLand.png) no-repeat center;
							background-size: 20px;
						}
						.icon-Date {
							background: url(https://com.airpremiacdn.net/resources/onepoint/images/depArrCheck/Date.png) no-repeat center;
							background-size: 20px;
						}
						.icon-roundTrip {
							background: url(https://com.airpremiacdn.net/resources/onepoint/images/depArrCheck/roundTrip.svg) no-repeat center;
							background-size: 20px;
						}

						>.slctCon {
							top: 44px;
							border-radius: var(--Radius-xl, 16px);
							border: 1px solid var(--Border-Neutral-border6, #333D4B);
							background: var(--Container-neutral-container1, #FFF);
							overflow: hidden;
							box-shadow: none;
							max-height: none;

							.close-x {
								display: none;
							}

							@include rwd('lg') {
								&.pop {
									border: 0 none;
								}
								////width: auto;
								//position: absolute;
								//top: 44px !important;
								//right: auto !important;
								//bottom: auto !important;
								//left: auto !important;
								//inset: auto;
								//min-width: 340px;
								//
								//.daterangeWrap .topArea {
								//	padding-top: 0;
								//}

							}
							@include rwd('sm') {
								width: 100%;
								min-width: unset;
								position: fixed;
								top: 0 !important;
								right: 0 !important;
								bottom: 0 !important;
								left: 0 !important;
								inset: 0;
								border: 0;
								border-radius: 0;
								z-index: 111;

								.daterangeWrap .calendarWrap .calendar .list>ul {
									width: 100%;
								}

								.close-x {
									padding: 0.25rem 1.5rem;
									display: block;
									position: relative;
									height: 45px;
									margin-top: 10px;
									h3 {
										color: var(--On-View-onView1, #171A22);
										font-size: 20px;
										font-style: normal;
										font-weight: 700;
										line-height: 30px; /* 150% */
										margin: 0;
									}
									.iconWrap {
										position: absolute;
										right: 20px;
										top: 10px;
									}
								}
							}
						}

						.asOf {
							width: 100%;
							color: var(--On-View-onView1, #171A22);
							/* Label lg/Medium */
							font-size: 16px;
							font-style: normal;
							font-weight: 500;
							line-height: 24px; /* 150% */
						}
					}
					.fName {
						width: auto;
						color: var(--On-View-onView1, #171A22);
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: 24px; /* 150% */
					}
					.arrBar {
						width: 1px;
						height: 15px;
						background: var(--On-View-onView4, #9AA2B1);
					}
					.fNum {
						flex: 1;
						font-size: 16px;
						&::placeholder {
							color: var(--On-View-onView4, #9AA2B1);
							font-weight: 500;
						}
					}
				}
				.searchBtn {
					display: block;
					width: auto;
					border-radius: var(--Radius-xl, 16px);
					background: var(--Container-brand-primary, #0A3162);
				}
				.caution-msg{
					color: var(--On-View-onView3, #6D7788);
					text-align: center;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px; /* 157.143% */
				}
			}
			.body {
				flex: 1;
				.checkRes {
					margin: 0;

					&.none {
						border-bottom: 0 none;
						height: auto;
						margin: 100px 0;

						display: flex;
						flex-direction: column;
						align-items: center;
						gap: 30px;

						color: var(--On-View-onView2, #333D4B);
						text-align: center;

						font-size: 24px;
						font-style: normal;
						font-weight: 700;
						line-height: 36px; /* 150% */

						&:before {
							content: '';
							display: flex;
							width: 68px;
							height: 68px;
							justify-content: center;
							align-items: center;
							gap: 10px;

							border-radius: var(--Radius-Full, 10000px);
							background: url(https://com.airpremiacdn.net/resources/onepoint/images/depArrCheck/Flight.png) #F4F6F8 no-repeat center center;
							background-size: 32px;
						}
					}
				}
				.cautionWrap {
					padding: 20px 0;
					margin-top: 0;
					.title {
						background: url(https://com.airpremiacdn.net/resources/onepoint/images/depArrCheck/Information.svg) no-repeat left;
						background-size: 24px;
						padding-left: 28px;

						color: var(--On-View-onView1, #171A22);
						font-size: 16px;
						font-style: normal;
						font-weight: 700;
						line-height: 24px; /* 150% */
						margin-bottom: 8px;
					}
					.list {
						padding-left: 7px;
						color: var(--On-View-onView3, #6D7788);
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 22px; /* 157.143% */
					}
				}
			}

			@include rwd('lg'){
				gap: 24px;
				.aside {
					width: 270px;
				}
			}
			@include rwd('md'){
				flex-direction: column;
				.aside {
					width: 100%;
				}
			}
		}
	}

	.daterangeWrap {
		.calendarWrap {
			margin: 18px 12px 0;
		}

		@include rwd('sm') {
			padding: 0;
		}
	}
}


/****************
***** 이벤트 *****
****************/


/* promotionWrap */
.promotionWrap li {
    width: calc(50% - #{toRem(32)});
    margin-right: toRem(60);
    padding-bottom: toRem(60);
    display: inline-block;
    vertical-align: top;
}
.promotionWrap li:nth-child(even) {
    margin-right: 0;
}
.promotionWrap li .imgRound {
    border-radius: toRem(30);
}
.promotionWrap li .badgeWrap {
    margin-top: toRem(20);
}
.promotionWrap li .title {
    margin-top: toRem(5);
    font-weight: 700;
}
.promotionWrap li .title a:hover {
    color: $ap-point-orange;
}
.promotionWrap .imgRound {
	aspect-ratio: 610 / 390;
}
.promotionWrap .imgRound > img {
	height: 100%;
}


/* promotionDetail */
.promotionDetailWrap .badgeWrap {
    margin-bottom: toRem(20);
}
.promotionDetail {
    padding: toRem(60) 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: toRem(2) solid $ap-line-black;
    border-bottom: toRem(2) solid $ap-line-black;
}
.promotionDetail.bDn {
	border: 0;
}
.promotionDetail .imgRound {
    width: 33%;
    border-radius: toRem(30);
    aspect-ratio: 410 / 580;
}
promotionDetail .imgRound > img {
	height: 100%;
}
.promotionDetail .list {
    width: 66%;
    margin-left: toRem(60);
}
.promotionDetail .list h5 {
	margin-bottom: toRem(60);
	font-size: toRem(18);
}
.promotionDetail .list dl {
    width: 100%;
    padding: toRem(20) 0;
    display: table;
    border-bottom: toRem(1) solid $ap-line-gray;
}
.promotionDetail .list dl dt {
    width: toRem(140);
    margin-right: toRem(10);
    padding-right: toRem(20);
    display: table-cell;
    font-weight: 700;
}
.promotionDetail .list dl dd {
    display: table-cell;
}
.promotionDetail .list > strong {
    margin-bottom: toRem(20);
    display: block;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: toRem(-0.2);
}
.promotionDetail .list dl dd .orange {
    color: $ap-solid-orange;
}
.promotionDetail .list .prBtnGr {
	margin-top: toRem(60);
	display: flex;
	justify-content: right;
}
.promotionDetail .list .prBtnGr .taskButton {
	width: toRem(200);
	margin-right: toRem(10);
	letter-spacing: toRem(-0.5);
}
.promotionDetail .list .prBtnGr .taskButton:last-child {
	margin-right: 0;
}
.promotionDetailWrap .contents.evDtl {
	border-top: toRem(2) solid $ap-line-black;
	border-bottom: toRem(2) solid $ap-line-black;
}
.promotionDetailWrap .bottomBtn {
	margin-top: toRem(120);
}
.promotionDetailWrap .bottomBtn .taskButton {
	width: toRem(200);
	color: $ap-text-black;
}
.evntPrmWrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.evntPrmBox {
	width: toRem(250);
	height: toRem(250);
	margin-bottom: toRem(40);
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/promotion/evntPrmBg.png')no-repeat;
	background-size: toRem(250);
}
.pntBg {
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/promotion/evntPntBg.png') no-repeat;
	background-size: toRem(250);
}
.evntPrmCd {
	padding-top: toRem(130);
	padding-left: toRem(50);
	font-size: 3.2rem;
	letter-spacing: -0.03em;
}
.evntPrmWrap .txtWrap {
	width: 100%;
	display: flex;
	justify-content: center;
}
.evntPrmWrap .txtWrap .poIt {
	font-size: 3rem;
	font-family: 'Premia';
}
.popCouDwn .popScr .imgCoupon {
	width: toRem(250);
	height: toRem(250);
	margin: 0 auto;
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/promotion/envtCouBg.png') no-repeat center;
	background-size: toRem(250);
}

/* promotionPublish */
.eventFst {
	padding: toRem(40);
	text-align: center;
	background: $ap-green-1;
}
.eventFst .eventTit {
	margin: toRem(40) 0 toRem(20);
	color: $ap-black;
	font-size: 4rem;
	font-weight: 600;
}
.eventFst h3 {
	font-size: 3.2rem;
	font-weight: 400;
}
.eventFst .cpBoxWrap {
	padding: toRem(40) 0;
}
.eventFst .cpBoxWrap.trWrap {
	padding-top: toRem(80);
}
.eventFst .cpBoxCont {
	padding: 0 toRem(60);
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.eventFst .couPn {
	width: 100%;
	max-width: toRem(300);
	height: toRem(300);
	position: relative;
	background: $ap-white;
}
.eventFst .couPn:last-child {
	margin-right: 0;
}
.eventFst .couPn a {
	width: 100%;
	height: 100%;
	display: block;
}
.eventFst .couPn .cpnTit {
	padding-top: toRem(40);
	display: flex;
	justify-content: center;
	align-items: center;
}
.eventFst .couPn .cpnTit p {
	color: $ap-point-orange;
	font-size: 3.2rem;
	font-weight: 600;
	font-family: 'Premia'
}
.eventFst .couPn .cpnTit .enArw {
	width: toRem(46);
	height: toRem(15);
	background: url(https://com.airpremiacdn.net/resources/onepoint/images/promotion/test/circleArrow.png) no-repeat center;
}
.eventFst .couPn .cpnCont {
	margin-top: toRem(25);
	display: flex;
	justify-content: center;
	align-items: center;
}
.eventFst .couPn .cpnCont p {
	color: $ap-text-gray-0;
	font-size: 2.6rem;
	font-weight: 600;
}
.eventFst .couPn .cpnCont .koArw {
	width: toRem(46);
	height: toRem(34);
	background: url(https://com.airpremiacdn.net/resources/onepoint/images/promotion/test/lineArrow.png) no-repeat center;
}
.eventFst .couPn .cpnPrc {
	margin-top: toRem(30);
	font-size: 3rem;
	color: $ap-point-orange;
}
.eventFst .couPn .cpnPrc > span {
	font-size: 4.5rem;
	font-weight: 700;
}
.eventFst .couPn .evtBtn {
	width: 100%;
	height: toRem(70);
	font-size: 2.2rem;
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: $ap-black;
	border-radius: 0;
}
.eventFst .couPn .cpnImg {
	width: 100%;
	height: 100%;
	margin: 0 auto;
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/promotion/test/cpn.png') no-repeat center;
	background-size: toRem(300);
}
.eventFst .cpBoxCont .trPc {
	height: toRem(400);
	position: relative;
}
.eventFst .cpBoxCont .trPc .tourCont .trCpimg {
	width: 100%;
	height: 100%;
	background: url(https://com.airpremiacdn.net/resources/onepoint/images/promotion/test/cpImg01.png) no-repeat center;
	background-size: toRem(370);
}
.eventFst .cpBoxCont .trPc .tourCont .trCpimg.siNp {
	background: url(https://com.airpremiacdn.net/resources/onepoint/images/promotion/test/cpImg02.png) no-repeat center;
	background-size: toRem(370);
}
.eventFst .cpBoxCont .trPc .tourCont .trCpimg.sgNm {
	background: url(https://com.airpremiacdn.net/resources/onepoint/images/promotion/test/cpImg03.png) no-repeat center;
	background-size: toRem(370);
}
.eventFst .couPn .trCptxt {
	width: 100%;
	height: toRem(90);
	padding: toRem(10) toRem(20);
	text-align: left;
	position: absolute;
	left: 0;
	bottom: toRem(70);
	background-color: rgba(0,0,0,0.6);
}
.eventFst .couPn .trCptxt p {
	color: $ap-white;
	font-size: 2.2rem;
}
.eventFst .couPn .trCptxt .pRc {
	margin-top: toRem(5);
	color: $ap-solid-orange;
	font-size: 2.6rem;
}
.eventFst .couPn .trCptxt .pRc span {
	font-size: 1.8rem;
}
.eventFst .couPn .evtBtn.trBtn {
	padding: 0 toRem(20);
	text-align: left;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.eventFst .couPn .evtBtn.trBtn::after {
	content: "";
	width: toRem(38);
	height: toRem(18);
	display: block;
	background: url(https://com.airpremiacdn.net/resources/onepoint/images/promotion/test/nextevArrow.png) no-repeat center;
}
.eventFst .cpnPrd {
	font-size: 2rem;
	justify-content: center;
}
.eventFst .cpnPrd .svcPrd .lnTit:first-child {
	padding-right: toRem(40);
}

/* 이벤트 버튼 2개 */
.evtBtnWrap {
	height: auto;
	padding: toRem(50) toRem(180);
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: $ap-text-black;
}
.evtBtnWrap > a {
	width: 100%;
	height: toRem(100);
	padding: toRem(25) toRem(50);
	text-align: center;
	background: $ap-solid-orange;
	border-radius: toRem(50);
}
.evtBtnWrap > a:first-child {
	margin-right: toRem(60);
}
.evtBtnWrap > a > button {
	color: $ap-white;
	font-size: 2.8rem;
}
.evtBtnWrap.sgBtn {
	height: auto;
	text-align: center;
	display: block;
}
.evtBtnWrap.sgBtn > a {
	width: toRem(380);
}
.evtBtnWrap.sgBtn > a:first-child {
	margin-right: 0;
}

@media only screen and (max-width: 1200px) {
	/* promotionPublish */
	.eventFst .couPn {
		width: toRem(240);
		height: toRem(240);
	}
	.eventFst .couPn.trPc {
		height: toRem(360);
	}
	.eventFst .couPn .cpnTit {
		padding-top: toRem(20);
	}
	.eventFst .couPn .cpnTit p {
		font-size: 2.4rem;
	}
	.eventFst .couPn .cpnTit .enArw {
		background-size: toRem(30);
	}
	.eventFst .couPn .cpnCont {
		margin-top: toRem(15);
	}
	.eventFst .couPn .cpnCont .koArw {
		background-size: toRem(30);
	}
	.eventFst .couPn .cpnCont p {
		font-size: 2rem;
	}
	.eventFst .couPn .cpnPrc {
		margin-top: toRem(20);
		font-size: 2.4rem;
	}
	.eventFst .couPn .cpnPrc > span {
		font-size: 3.6rem;
	}
	.eventFst .couPn .evtBtn {
		font-size: 2rem;
	}

	/* 이벤트 버튼 2개 */
	.evtBtnWrap {
		padding: toRem(50) toRem(180);
	}
	.evtBtnWrap > a {
		height: toRem(80);
		padding: toRem(20) toRem(30);
	}
	.evtBtnWrap > a > button {
		font-size: 2rem;
	}
}
@media only screen and (max-width: 1000px) {
	/* promotionPublish */
	.eventFst .eventTit {
		font-size: 3.2rem;
	}
	.eventFst h3 {
		font-size: 2.6rem;
	}
	.eventFst .cpBoxCont {
		padding: 0;
	}
	.eventFst .couPn {
		width: toRem(200);
		height: toRem(200);
	}
	.eventFst .couPn .cpnTit {
		padding-top: toRem(15);
	}
	.eventFst .couPn .cpnTit p {
		font-size: 2.2rem;
	}
	.eventFst .couPn .cpnCont {
		margin-top: toRem(10);
	}
	.eventFst .couPn .cpnCont .koArw {
		width: toRem(36);
	}
	.eventFst .couPn .cpnPrc {
		margin-top: toRem(15);
		font-size: 2.4rem;
	}
	.eventFst .couPn .cpnPrc > span {
		font-size: 3rem;
	}
	.eventFst .couPn .evtBtn {
		height: toRem(50);
		font-size: 2rem;
	}
	.eventFst .couPn .trCptxt {
		bottom: toRem(50);
	}
	.eventFst .couPn .trCptxt p {
		font-size: 1.8rem;
	}
	.eventFst .couPn .trCptxt .pRc {
		font-size: 2.2rem;
	}
	.eventFst .couPn .evtBtn.trBtn {
		padding-right: toRem(10);
		font-size: 1.6rem;
	}
	.eventFst .couPn .evtBtn.trBtn::after {
		background-size: toRem(28);
	}
	.eventFst .cpnPrd {
		font-size: 1.6rem;
	}

	/* 이벤트 버튼 2개 */
	.evtBtnWrap {
		padding: toRem(50) toRem(130);
	}
}

@include rwd('sm'){
    .promotionWrap li {
        width: 100%;
        margin-right: 0;
    }
    .promotionWrap li .imgRound {
        width: 100%;
        max-width: toRem(400);
        margin: 0 auto;
        border-radius: toRem(20);
    }
    .promotionWrap li .title {
        font-size: toRem(14);
    }
    .promotionWrap .ftG {
        font-size: toRem(14);
    }

    .promotionDetail {
        padding-bottom: 0;
        display: block;
    }
    .promotionDetail .imgRound {
    	width: 100%;
    	max-width: toRem(360);
    	margin: 0 auto;
    	border-radius: toRem(20);
    }
    .promotionDetail .list {
        width: 100%;
        margin-left: 0;
        margin-top: toRem(60);
    }
    .promotionDetail .list h5 {
    	margin-bottom: toRem(30);
        font-size: toRem(16);
    }
    .promotionDetail .list > strong  {
        margin-top: toRem(60);
        font-size: toRem(18);
    }
    .promotionDetail .list dl {
    	padding: toRem(16) 0;
        font-size: toRem(14);
    }
    .promotionDetail .list dl:last-child {
        border-bottom: 0;
    }
    .promotionDetail .list dl dt {
        width: toRem(100);
    }
    .promotionDetail .list .prBtnGr {
    	flex-direction: column;
    	align-items: center;
    }
    .promotionDetail .list .prBtnGr .taskButton {
    	margin: 0 0 toRem(15) 0;
    }
    .promotionDetail .list .prBtnGr .taskButton:last-child {
    	margin: 0 0 toRem(60) 0;
    }
    .promotionDetailWrap .bottomBtn {
		margin-top: toRem(60);
	}

	/* promotionPublish */
	.eventFst .eventTit {
		font-size: 2.8rem;
	}
	.eventFst h3 {
		font-size: 2.2rem;
	}
	.eventFst .cpBoxCont {
		flex-direction: column;
	}
	.eventFst .couPn {
		width: 100%;
		height: toRem(220);
		margin-right: 0;
		margin-bottom: toRem(20);
	}
	.eventFst .couPn:last-child {
		margin-bottom: 0;
	}
	.eventFst .couPn .cpnTit {
		padding-top: toRem(30);
	}
	.eventFst .cpnPrd {
		font-size: 1.4rem;
	}
	.eventFst .cpnPrd .svcPrd {
		padding-bottom: toRem(20);
		flex-direction: column;
	}
	.eventFst .cpnPrd .svcPrd .lnTit:first-child {
		padding-right: 0;
		padding-bottom: toRem(20);
	}
	.eventFst .couPn .trCptxt {
		padding: toRem(20);
	}
	.eventFst .cpBoxWrap.trWrap {
		padding-top: toRem(40);
	}

	/* 이벤트 버튼 2개 */
	.evtBtnWrap {
		padding: toRem(50);
		text-align: center;
		display: block;
	}
	.evtBtnWrap > a {
		width: toRem(310);
		height: toRem(40);
		padding: toRem(6) toRem(30);
		margin: 0 auto;
	}
	.evtBtnWrap > a:first-child {
		margin-right: 0;
		margin-bottom: toRem(30);
	}
	.evtBtnWrap > a > button {
		font-size: 1.6rem;
	}
	.evtBtnWrap.sgBtn > a {
		width: toRem(310);
	}
	.evtBtnWrap.sgBtn > a:first-child {
		margin-bottom: 0;
	}
}

@media only screen and (max-width: 479px) {
	.evtBtnWrap {
		padding: toRem(30) toRem(50);
	}
	.evtBtnWrap > a {
		width: toRem(170);
		height: toRem(30);
		padding: toRem(3) toRem(30);
	}
	.evtBtnWrap > a:first-child {
		margin-bottom: toRem(20);
	}
	.evtBtnWrap > a > button {
		font-size: 1.4rem;
	}
	.evtBtnWrap.sgBtn > a {
		width: toRem(170);
	}
}
@include rwd('xs'){
	.evntPrmBox,
	.popCouDwn .popScr .imgCoupon {
		width: toRem(220);
		height: toRem(220);
		background-size: toRem(220);
	}
	.evntPrmCd {
		padding-top: toRem(120);
		padding-left: toRem(42);
		font-size: 2.8rem;
	}
}


/****************
***** 운임안내 ****
****************/


.fareWrap > div {
	margin-top: toRem(100);
}
.fareWrap > div:first-child {
	margin-top: 0;
}
.fareWrap .txt {
	margin-top: toRem(30);
}
.fareWrap .txt strong {
	color: $ap-solid-orange;
	font-weight: 700;
}
.fareWrap .txt p {
	font-size: 1.4rem;
}
.fareWrap .infoArea .title {
    padding-bottom: toRem(60);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: toRem(2) solid $ap-line-black;
}
.fareWrap .infoArea .infoCont {
	margin-top: toRem(40);
	padding-right: toRem(20);
}
.fareWrap .infoArea .infoCont h3 {
	margin-top: toRem(10);
	margin-bottom: toRem(20);
	font-weight: 700;
	font-size: toRem(28);
	line-height: toRem(36);
	letter-spacing: toRem(-0.6);
}
.fareWrap .infoArea .infoCont h3.pr {
	margin-bottom: toRem(40);
}
.fareWrap .infoArea .infoCont h3.sub {
    font-size: 0.8em;
}
.fareWrap .infoArea .infoCont p {
	margin-bottom: toRem(10);
	letter-spacing: toRem(-0.28);
}
.fareWrap .infoArea .infoPic img {
    height: toRem(250);
    display: flex;
    justify-content: end;
}
.fareWrap .infoArea .infoPic.premia img {
    height: toRem(200);
}
.fareWrap .infoArea .seatImg {
	margin-top: toRem(60);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.fareWrap .infoArea .seatImg > img {
  	width: 48%;
    border-radius: toRem(20);
}
.fareWrap .classArea {
    padding: toRem(40);
    background-color: $ap-class-1;
    border-radius: toRem(20);
}
.fareWrap .classArea h5 {
    color: $ap-solid-orange;
	font-size: toRem(28);
	line-height: toRem(36);
    font-weight: 700;
}
.fareWrap .classArea .des {
 	margin-top: toRem(10);
}
.fareWrap .classArea .illustBox {
    margin: toRem(30) 0 toRem(20);
}
.fareWrap .classArea .illustBox .illust {
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
}
.fareWrap .classArea .iconBox {
	margin: toRem(20);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.fareWrap .classArea .iconBox.mr20{
    margin-right: toRem(20);
}
.fareWrap .classArea .iconBox .imgBox {
	width: toRem(150);
	padding: toRem(10);
	display: flex;
	justify-content: center;
	background: $ap-class-2;
	border-radius: toRem(10);
}
.fareWrap .classArea .iconBox .imgBox img {
    width: toRem(130);
}
.fareWrap .classArea .iconBox p {
    margin-top: toRem(20);
    text-align: center;
  }
.fareWrap .classArea .iconBox .imgBox img.size100 {
    width: toRem(100);
    margin: toRem(10);
    padding: toRem(5) 0;
}
.fareWrap .classArea .iconBox .imgBox img.size90 {
    width: toRem(90);
    margin: toRem(30);
}
.fareWrap .movieArea .movie {
    margin-top: toRem(30);
}
.fareWrap .movieArea .movie > iframe {
    width: 100%;
    height: toRem(700);
    border: 0;
    border-radius: toRem(20);
}

@include rwd('md'){
	.fareWrap .infoArea .title {
		flex-direction: column;
	    align-items: center;
	}
	.fareWrap .infoArea .infoPic img {
		height: toRem(200);
	}
}
@include rwd('sm'){
	.fareWrap .txt {
		margin-top: toRem(20);
	}
	.fareWrap .classArea .illustBox {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: center;
	}
	.fareWrap .classArea .illustBox .illust {
		display: block;
		min-height: toRem(690);
	}
   .fareWrap .classArea .iconBox p {
        min-height: toRem(50);
    }
	.fareWrap > div {
	    margin-top: toRem(60);
	}
	.fareWrap .infoArea .title {
	    padding-bottom: toRem(30);
	    display: block;
	}
	.fareWrap .infoArea .infoCont {
	    margin-top: 0;
	    margin-bottom: toRem(20);
	    padding-right: 0;
	}
	.fareWrap .infoArea .infoCont h3 {
	    margin-bottom: 0;
		font-size: toRem(24);
	}
	.fareWrap .infoArea .infoCont p {
	    margin-bottom: 0;
	    font-size: toRem(14);
	}
	.fareWrap .infoArea .infoPic img {
	    height: toRem(180);
	}
	.fareWrap .infoArea .seatImg {
	    margin-top: toRem(30);
	    display: block;
	}
	.fareWrap .infoArea .seatImg > img {
		width: 100%;
		margin-top: toRem(20);
	    display: block;
	}
	.fareWrap .infoArea .seatImg > img:first-child {
		margin-top: 0;
	}
	.fareWrap .classArea h5 {
		font-size: toRem(24);
	}
	.fareTbTxt {
	    margin-top: toRem(20);
	    font-size: toRem(14);
	}
}

@include rwd('xs'){
	.fareWrap .movieArea .movie > iframe {
        height: toRem(300);
    }
	.fareWrap .infoArea .infoPic img {
	    max-width: 100%;
	    height: auto;
	}
}


/*****************
**** 서비스 안내 ****
*****************/


/* 공통*/
.aloneBox {
	margin-top: toRem(30);
	display: flex;
	justify-content: center;
}
.aloneBox.left {
	justify-content: flex-start;
}


/* 수하물 */
.OneBaggBox {
	width: toRem(58);
	height: toRem(112);
	margin-right: toRem(10);
	display: inline-flex;
	align-items: center;
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/support/oneBaggage.png') no-repeat;
}
.OneBaggBox:last-child {
	margin-right: 0;
}
.OneBaggBox span {
	width: 100%;
	padding-top: toRem(10);
	font-size: toRem(14);
}
.NoBaggBox {
	width: toRem(98);
	height: toRem(112);
	display: inline-flex;
	align-items: center;
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/support/noBaggage.png') no-repeat;
}


/* 사전 수하물 구매 */
.bggSum .slctBox {
	width: 47%;
}
.bggSum .slctBox button.val {
	padding: toRem(12) 0;
}
.bggSum .slctBox {
	margin-right: 0;
}
.bggSum .slctBox > .slctCon {
	top: toRem(40);
}
.bggSum.dacWrap .secSlt .dateWrapper .slctCon {
	width: toRem(800);
}
.bggSum .dateWrapper::after {
	display: none;
}
.bggSum .dateWrapper button.val {
	font-size: 1.6rem;
}
.bggSum .bggSumSlt {
	margin-top: toRem(25);
}
.bggFare,
.addBgg {
	border-radius: toRem(12);
	border: toRem(1) solid $ap-text-gray-4;
}
.bggFare li {
	width: 100%;
	border-right: toRem(2) dotted $ap-text-gray-4;
}
.bggFare li:last-child {
    border-right: 0;
}
.bggFare .rdoBox {
	width: 100%;
	padding-left: 0;
}
.bggFare .rdoBox > input.ipt,
.bggFare .rdoBox:before {
	top: toRem(42);
	left: toRem(40);
}
.bggFare .rdoBox label {
	width: 100%;
	height: auto;
	padding: toRem(40) toRem(40) toRem(40) toRem(80);
	display: block;
	font-size: toRem(20);
	font-weight: 600;
}
.bggFare .rdoBox label span {
	display: block;
	font-size: toRem(16);
	font-weight: 400;
}
.bggTotal li {
	padding: toRem(40);
}
.bagWgtWrap {
	margin-top : toRem(10);
	font-size: toRem(18);
}
.bagWgtWrap .icon {
	background-position: toRem(-7) 0;
}
.bagWgtWrap .gdNum {
	font-size: toRem(18);
}
.addBgg {
	margin-top: toRem(20);
	padding: toRem(40) toRem(30);
}
.addBgg > span {
	font-size: toRem(20);
	font-weight: 600;
}
.addBgg > span .icon {
	margin-right: toRem(15);
}
.addBggSlt {
	width: calc(100% - #{toRem(250)});
	justify-content: end;
}
.addBggSlt .addBggSltItm {
	width: auto;
	margin-left: toRem(40);
	position: relative;
}
.addBggSlt .addBggSltItm:before {
	content: '';
	width: toRem(1);
	height: toRem(15);
	position: absolute;
	top: 50%;
	left: toRem(-25);
	transform: translateY(-50%);
	background: $ap-line-gray;
}
.addBggSlt .addBggSltItm:first-child:before {
	display: none;
}
.addBggSlt .addBggSltItm .slctBox {
	width: auto;
}
.addBggSlt .addBggSltItm .slctBox span {
	word-break: keep-all;
}
.bggSumSlt .sub,
.pch .sub {
	margin-top: toRem(10);
}
.pch .sub .icon {
	margin-right: toRem(5);
}
.addBggPay .contents.flex {
	align-items: flex-start;
}
.sltBagWgt {
	width: calc(100% - #{toRem(867)});
	height: toRem(117);
	display: flex;
	align-items: center;
}
.sltBagWgt .sltBagWgtImg {
	width: toRem(117);
	height: toRem(117);
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/support/illust_carrier.png') no-repeat;
}
.sltBagWgt dl {
	margin-top: toRem(20);
	margin-left: toRem(10);
}
.sltBagWgt dl dt {
	margin-bottom: toRem(10);
	color: $ap-text-gray-1;
	line-height: 1em;
}
.sltBagWgt dl dd {
	font-size: toRem(24);
}
.pch {
	width: toRem(867);
}
.pchCon {
	display: flex;
	justify-content: center;
}
.pchCon dt {
	padding-right: toRem(40);
	font-size: toRem(18);
	font-weight: 600;
}
.pchCon dd span {
	color: $ap-text-gray-1;
}
.pchCon dd strong {
	display: block;
	font-size: toRem(24);
	font-weight: 600;
}
.sptLnk {
	flex-wrap: wrap;
}
.sptLnk li {
	width: 100%;
}
.sptLnk li a {
	width: 100%;
	padding: toRem(14) 0;
	border-bottom:toRem(1) solid $ap-line-gray;
}
.sptLnk li a:hover .txt {
	text-decoration: underline;
}


/* 사전 좌석 구매 */
.seatAddCon {
	position: relative;
}
.seatAddCon .seatImgBox {
	max-width: toRem(1200);
	width: 100%;
	margin: toRem(60) auto 0;
}
.seatImgBox img {
	width: 100%;
}
.btnSeatView {
	height: toRem(26);
	padding: 0 toRem(10);
	display: none;
	color: $ap-white;
	font-size: toRem(16);
	font-weight: 400;
	line-height: toRem(22);
	background-color: $ap-solid-skyblue;
	border: toRem(1) solid $ap-solid-skyblue;
	border-radius: toRem(12);
}
.btnSeatView.on {
	color: $ap-solid-skyblue;
	background-color: $ap-white;
}
.seatImgBoxM {
	height: toRem(400);
	margin-top: toRem(150);
	overflow: hidden;
	display: none;
	text-align: center;
}
.seatImgBoxM:after {
	content: "";
	width: 100%;
	height: toRem(100);
	position: absolute;
	left: 0;
	bottom: 0;
	background: rgb(255,255,255);
	background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 75%);
}

.seatImgBoxM img {
	width: 100%;
	max-width: 420px;
}
.seatAddCon.full .seatImgBoxM {
	height: auto;
}
.seatAddCon.full .seatImgBoxM:after {
	display: none;
}
.seatAddCon.full .btnSeatView.bottom {
	margin-top: toRem(30);
}
.seatImgTxt {
	width: 100%;
	margin-top: toRem(30);
	justify-content: center;
}
.seatImgTxt li {
	margin-right: 28px;
	display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
}
.seatImgTxt li:last-child  {
	margin-right: 0;
}
.seat {
	width: toRem(25);
    height: toRem(15);
    vertical-align: middle;
	border-radius: toRem(8) toRem(4) toRem(4) toRem(8);
}
.seat.gray {
	background: $ap-modern-3;
}
.seat.dark {
	background: $ap-solid-darkblue;
}
.seat.sky {
	background: $ap-point-info;
}
.seat.sky2 {
	background: $ap-solid-skyblue;
}
.seatIcon{
	width: 22px;
	height: 17px;
	vertical-align: middle;
}
.seatIcon.exit {
	background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/ico-emergency.svg")
}
.seatIcon.row1 {
	background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/ico-seat1.svg")
}
.seatIcon.row2 {
	background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/ico-seat2.svg")
}
.seatIcon.row3 {
	background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/ico-seat3.svg")
}
.seatSpecialIcon{
	width: 18px;
	height: 18px;
	vertical-align: middle;
}
.seatSpecialIcon.disabled {
	background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/ico-disabled.svg")
}
.seatSpecialIcon.bassinet {
	background: url("https://com.airpremiacdn.net/resources/onepoint/images/icon/ico-bassinet.svg")
}

@include rwd('md'){
	.seatAddCon {
		text-align: center;
	}
	.seatImgBox {
		display: none;
	}
	.seatImgBoxM {
		display: block;
	}
	.seatAddWrap .subSmTit {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.btnSeatView {
		display: inline-block;
	}
	.btnSeatView.bottom {
		display: none;
	}
	.seatAddCon.full .btnSeatView.bottom {
		display: inline-block;
	}
	.seatImgTxt {
		margin-top: 0;
		position: absolute;
		top: toRem(140);
	}
	.seatAddWrap .tbWrap .seat {
		margin: 0 auto toRem(5);
		display: block;
	}
}

@include rwd('sm'){
	.seatImgTxt {
		justify-content: space-between;
	}
	.seatImgTxt li {
		margin-right: 0;
	}
	.btnSeatView {
		height: toRem(22);
		font-size: toRem(13);
		line-height: toRem(20);
	}
}


@media only screen and (max-width: 499px) {
	.seatImgTxt {
		flex-direction: column;
	}
	.seatImgTxt li {
		width: toRem(180);
		display: block;
	}
	.seatImgBoxM {
		margin-top: toRem(250);
	}
}


/* 공항에서의 옆좌석 구매 */
.sideSeatImgBox {
	width: toRem(750);
	margin: toRem(30) auto 0;
}

@include rwd('sm'){
	.sideSeatImgBox {
		width: 100%;
		max-width: toRem(750);
		margin: toRem(20) auto 0;
	}
	.sideSeatImgBox img {
		width: 100%;
		max-width: toRem(750);
	}
}

/* 취항지 공항 정보 */
.icnCom {
	justify-content: center;
}
.icnImgBox {
	width: 100%;
	height: toRem(450);
	display: flex;
	justify-content: center;
	align-items: center;
	background: $ap-text-gray-5;
}
.icnImgBox img{
	max-width: 760px;
	width: 100%;
}

	/* 탑승 수속 */
.kskNum {
	margin-right: toRem(5);
}
.infoTxt {
	display: flex;
	align-items: center;
}
.bodStp {
	padding-left: toRem(30);
	display: flex;
	align-items: center;
}
.bodStp .badgeArrow.rvs {
	padding: 0 toRem(8);
	margin-right: toRem(5);
}
.bodStp .stpValue i {
	margin-right: toRem(5);
}

@include rwd('lg'){
	.addBggPay .contents.flex {
		flex-wrap: wrap;
	}
	.sltBagWgt {
		width: 100%;
		margin: toRem(20) 0;
		justify-content: center;
	}
	.pch {
		width: 100%;
	}
	.bggFare .rdoBox label {
		padding: toRem(30) toRem(30) toRem(300) toRem(80);
	}
}

@include rwd('md'){
	.addBggSlt {
		width: calc(100% - #{toRem(160)});
	}

	/* 탑승 수속 */
	.bodStp {
		padding-left: 0;
		flex-direction: column;
	}
	.bodStp .badgeArrow.rvs {
		margin-right: 0;
		margin-bottom: toRem(10);
	}
}
@include rwd('sm'){
	.dpBlk {
		display: block;
	}
	.lmtInfo {
		text-align: right;
	}
	.bagSize img {
		width: toRem(240);
		height: toRem(168);
	}
	.OneBaggBox {
		width: toRem(30);
		height: toRem(60);
		background-size: toRem(30);
	}
	.OneBaggBox span {
		display: none;
	}
	.tbWrap table tbody td.mobN {
		display: none;
	}
	.bggSumSlt .bggFare {
		flex-wrap: wrap;
	}
	.bggSumSlt .bggFare li {
		border-right: 0;
		border-bottom: toRem(2) dotted $ap-text-gray-4;
	}
	.bggSumSlt .bggFare .rdoBox label {
		padding: toRem(20) toRem(20) toRem(20) toRem(50);
	}
	.bggFare .rdoBox > input.ipt,
	.bggFare .rdoBox:before {
		top: toRem(22);
		left: toRem(20);
	}
	.bggFare li:last-child {
		border-bottom: 0;
	}
	.bggFare .rdoBox label {
		font-size: toRem(16);
	}
	.bggFare .rdoBox label span {
		font-size: toRem(13);
	}
	.addBgg {
		padding: toRem(30) toRem(20);
		display: block;
	}
	.addBgg > span {
		font-size: toRem(16);
	}
	.addBgg > span .icon {
		margin-right: toRem(5);
	}
	.addBggSlt {
		width: 100%;
		padding: 0 toRem(40);
		margin-top: toRem(10);
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.addBggSlt .addBggSltItm {
		width: 100%;
		margin: 0;
	}
	.addBggSlt .addBggSltItm:before {
		display: none;
	}
	.addBggSlt .addBggSltItm .slctBox,
	.addBggSlt .addBggSltItm .slctBox > .slctCon {
		width: 100%;
	}
	.pch .bggFare li {
		padding: toRem(20);
	}
	.pchCon {
		display: block;
	}
	.pchCon dt {
		padding-right: 0;
		margin-bottom: toRem(10);
		font-size: toRem(14);
	}
	.pchCon dd span {
		font-size: toRem(13);
	}
	.pchCon dd strong {
		font-size: toRem(20);
	}
	.pchCon dd strong .unt {
		font-weight: 400;
	}

	/* 취항지 공항 정보 */
	.icnImgBox {
		height: auto;
		padding: toRem(5);
	}
	.icnImgBox img {
		width: 100%;
	}
	.icnTb {
		margin-top: toRem(60);
	}
	.icnImgBox.SinImg {
		padding: toRem(30) toRem(20);
	}


	/* 탑승 수속 */
	.bodStp .stpValue {
		flex-direction: column;
	}
	.bodStp .stpValue .icon {
		width: toRem(20);
		height: toRem(20);
		background-size: cover;
	}
}
@media only screen and (max-width: 479px) {
	/* 탑승 수속 */
	.kskAlgn {
		text-align: left;
		display: block;
	}
}
@include rwd('xs'){
	.addBggSlt {
		padding: 0;
	}
	/* 탑승 수속 */
	.taL .listGlobal .btnBpop .txt {
		letter-spacing: -0.8;
	}
}
@media only screen and (max-width: 374px) {
	.pchCon dt {
		height: toRem(50);
	}
}

/****************
**** 고객지원 ****
****************/


/* 공지사항 */
.ntcList li {
    padding: toRem(30) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: toRem(1) solid $ap-line-gray;
    cursor: pointer;
}
.ntcList li:hover dt {
    color: $ap-point-orange;
}
.ntcList .ntclft {
    width: 85%;
}
.ntclft dt {
    font-size: toRem(20);
    font-weight: 700;
}
.ntclft dd {
    margin-top: toRem(16);
}
.ntcCont {
	word-break: keep-all;
	padding-top: toRem(60);
	border-top: toRem(2) solid $ap-line-black;
}
.ntcCont h3 {
    font-size: toRem(20);
    font-weight: 700;
}
.ntcCont .txtCont {
    margin-top: toRem(60);
    color: $ap-text-black;
}
.ntcCont .txtCont table td {
	vertical-align: middle;
}
.ntcBottom {
    padding: toRem(30) 0;
    margin-top: toRem(60);
    display: flex;
    justify-content: space-between;
    border-top: toRem(1) solid $ap-line-gray;
    border-bottom: toRem(1) solid $ap-line-gray;
}
@include rwd('sm'){
    .ntcList li {
        padding: toRem(20) 0;
        display: block;
    }
    .ntcList a {
        display: block;
    }
    .ntcList .ntclft {
        width: 100%;
    }
    .ntclft dt {
        font-size: toRem(18);
        letter-spacing: toRem(-0.4);
    }
    .ntclft dd {
        display: none;
    }
    .ntcList .ntcrgt {
        width: 100%;
        margin-top: toRem(10);
        text-align: left;
    }
    .ntcCont {
    	padding-top: toRem(40);
    }
	.ntcCont .txtCont {
		overflow-x: auto;
		margin-top: toRem(40);
	}
	.ntcCont .txtCont p{
		word-break: break-word !important;
	}

    .ntcBottom {
    	margin-top: toRem(40);
        padding: toRem(20) 0;
        display: block;
        font-size: 1.4rem;
    }
    .ntcBottom .title {
        margin-bottom: toRem(10);
		font-size: toRem(16);
    }
}

/* 자주 묻는 질문 */
.qus {
    margin-top: toRem(60);
    padding: toRem(14) 0;
    border-top: toRem(1) solid $ap-line-black;
    border-bottom: toRem(1) solid $ap-text-gray-3;
}
.qus strong {
    font-weight: 700;
}
.qusList {
    padding: toRem(30) 0;
    border-bottom: toRem(1) solid $ap-text-gray-3;
}
.qusList:last-child {
	border-bottom: 0;
}
.qusList .qst,
.qusList .asr {
    width: 100%;
    padding-left: toRem(60);
    position: relative;
    text-align: left;
}
.qusList .qst {
    padding-bottom: 0;
    position: relative;
}
.qusList .asr {
	padding-top: toRem(10);
}
.qusList .qst::before {
    content: "Q";
    color: $ap-text-gray-1;
}
.qusList .qst::before,
.qusList .asr::before {
    position: absolute;
    top: 50%;
	transform: translateY(-50%);
    left: toRem(20);
    font-weight: 700;
    font-size: toRem(30);
    white-space: pre-wrap;
}
.qusList .asr::before {
    content: "A";
    top: toRem(30);
    color: $ap-point-orange;
}
.qusList .qst > strong {
    display: block;
    font-size: toRem(20);
}
.qusList .qst .tglFtb {
    font-size: toRem(20);
    font-weight: 700;
}
.qusList .asr span {
    color: $ap-solid-skyblue;
}
.qusList .asr a {
	display: inline;
}

@include rwd('sm'){
    .qus {
        margin-top: toRem(40);
    }
    .qusList .qst,
    .qusList .asr {
        padding-left: toRem(30);
    }
    .qusList .qst .tglFtb {
    	font-size: toRem(16);
    }
    .qusList .qst::before,
    .qusList .asr::before {
        left: 0;
        font-size: toRem(30);
    }
}

/* 1:1문의하기 */
.inqWrapper .inputWrap {
	padding: toRem(60) 0;
    border-top: toRem(2) solid $ap-line-black;
    border-bottom: toRem(2) solid $ap-line-black;
}
.inqWrapper .inputWrap .row .label.alw::after {
    content: "*";
    margin-left: toRem(5);
    display: inline-flex;
    color: $ap-point-orange;
}
.fileWrap .down .mGr {
    margin-right: toRem(15);
    white-space: nowrap;
}
.inqWrapper .inputWrap .row textarea {
    font-size: 1.6rem;
}
.inqWrapper .inputWrap .row.file {
    border-bottom: 0;
}
.inqWrapper .cautionWrap {
    margin-top: toRem(60);
}
.persAgree {
    margin-top: toRem(60);
    padding: toRem(20);
    border: toRem(1) solid $ap-text-gray-3;
    border-radius: toRem(8);
}
.persAgree p {
    margin-top: toRem(10);
    color: $ap-text-gray-1;
}
@include rwd('sm'){
    .inqWrapper .inputWrap .row .value {
    	height: auto;
        padding: toRem(10) 0;
    }
    .inqWrapper .inputWrap .row textarea {
        font-size: 1.4rem;
    }
    .inqWrapper .inputWrap .row.file {
        display: flex;
    }
    .inqWrapper .inputWrap .row.file .value {
        padding-top: 0;
    }
	.inqWrapper .cautionWrap,
	.persAgree {
	    margin-top: toRem(30);
	}
    .fileWrap .downIp {
    	margin-bottom: toRem(5);
    }
}
@include rwd('xs'){
    .inqWrapper .inputWrap .row.file {
        display: block;
    }
    .inqWrapper .inputWrap .row.file .value {
        padding-top: toRem(10);
    }
}

/*1:1문의, qna 완료*/
.customComplete {
	padding-top: toRem(120);
	text-align: center;
}
.customComplete img {
	margin-bottom: toRem(20);
}
.customComplete .txt {
	margin: toRem(10) 0 toRem(80);
	color: $ap-text-gray-1;
	font-weight: 300;
}

/* 서식 자료실 */
.docu .fldown {
    vertical-align: middle;
}
.btnBlue.fldown {
    display: inline-flex;
}
.btnBlue.tw {
    margin-bottom: toRem(10);
}
.btnBlue.fldown::before {
    content: "";
    width: toRem(12);
    height: toRem(12);
    margin-right: toRem(4);
    background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/btn_download_blue.png) no-repeat;
}
.docmt table {
    width: 100%;
}
.docmt table tr {
    border-bottom: toRem(1) solid $ap-line-gray;
}
.docmt table th,
.docmt table td {
    padding: toRem(30) 0;
    vertical-align: middle;
}
.docmt table th {
    width: 80%;
    padding-right: toRem(20);
    text-align: left;
}
.docmt table td {
    position: relative;
    font-weight: 400;
    letter-spacing: toRem(-0.3);
    text-align: right;
    vertical-align: middle;
}
.docmt .tbsCr {
    margin-top: toRem(15);
    color: $ap-text-gray-1;
    font-weight: 300;
}
.docmt .tbTh {
    margin-top: toRem(10);
    font-size: 0.9em;
    font-weight: 300;
}
@include rwd('sm'){
    .docmt table th,
    .docmt table td {
        display: block;
    }
    .docmt table th {
        width: 100%;
        padding-bottom: toRem(20);
    }
    .docmt table td {
    	padding-top: 0;
        text-align: left;
    }
    .btnBlue.tw {
        margin-bottom: 0;
        margin-right: toRem(10);
    }
}

/* 기내 분실물 센터 */
.dacWrap .secSlt.lstCar .slctBox {
	width: 30%;
	padding: 0;
	border-bottom: 0;
}
.dacWrap .secSlt.lstCar .slctBox button.val {
	font-size: toRem(16);
}
.dacWrap .secSlt .slctBox .slctCon.rgt {
	width: unset;
}
.lostCtt .cttWrapper {
	flex: 1 1 0%;
}
.cttWrapper .row {
	padding: toRem(30) 0;
	position: relative;
	display: flex;
	align-items: center;
	border-bottom: toRem(1) solid $ap-text-gray-4;
}
.cttWrapper .row.inFo {
	padding: toRem(30) 0 toRem(20) 0;
	border-bottom: 0;
}
.cttWrapper .row .label {
	width: toRem(100);
	font-size: toRem(16);
	font-weight: 700;
	color: $ap-solid-skyblue;
}
.cttWrapper .row .value {
	position: relative;
	display: flex;
	align-items: center;
}
.cttWrapper .row .value::before {
	content: '';
	width: toRem(18);
	height: toRem(18);
	margin-right: toRem(15);
	padding-left: toRem(18);
	background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_check_in.svg) no-repeat center center;
	background-size: toRem(18);
}
.cttWrapper .row .value.resvt::before {
	background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_reservation_change.svg) no-repeat center center;
}
.cttWrapper .row .value.apbl::before {
	background: url(https://com.airpremiacdn.net/resources/onepoint/images/support/ico_airport_black.svg) no-repeat center center;
}
.cttWrapper .cttInfo {
	margin-left: toRem(150);
	display: block;
	font-weight: 400;
	color: $ap-text-gray-1;
}
.lostCtt .listGlobal {
	margin-top: 0;
	text-align: left;
}
.lostCtt .buttonWrapper {
	width: toRem(300);
	margin: toRem(60) auto 0;
}
.tbWrap.chkWrap {
	margin-top: toRem(40);
}

@include rwd('lg'){
	.lostCtt .inputWrap .row .value {
		padding-left: 0;
	}
}

@include rwd('md'){
	.lostCtt .infoInput .infotTl {
		margin-right: 0;
	}
}

@include rwd('sm'){
	.lostCarry .flex {
		display: block;
	}
	.lostCarry .dacWrap .secSlt {
		width: 100%;
		height: auto;
		padding: 0;
		display: block;
		border: 0;
	}
	.lostCarry .dacWrap .secSlt.lstCar .slctBox {
		width: 100%;
		padding: toRem(15) 0;
		display: block;
		border-bottom: toRem(1) solid $ap-line-gray;
	}
	.lostCarry .dacWrap .secSlt.lstCar .slctCon {
		top: toRem(50);
	}
	.lostCarry .dacWrap .secSlt .arrBar {
		display: none;
	}
	.lostCarry .dacWrap .taskButton {
		width: 100%;
		margin-top: toRem(30);
	}
	.cttWrapper .row {
		align-items: flex-start;
	}
	.cttWrapper .row .value {
		width: calc(100% - #{toRem(100)});
		align-items: flex-start;
	}
	.cttWrapper .row .value::before {
		margin-top: toRem(3);
	}
	.cttWrapper .cttInfo {
		margin-left: 0;
	}
	.lostCtt .buttonWrapper {
		width: 100%;
	}
	.cttWrapper .row .label {
		width: toRem(70);
	}
}


/****************
**** 운항 스케쥴 ***
****************/
.schBox {
	margin-top: toRem(30);
}
.schTit {
    margin-bottom: toRem(20);
	font-size: 2.4rem;
	font-weight: 700;
}
.schTimeData {
	width: auto;
    padding: toRem(10) 0;
    display: inline-block;
    text-align: center;
}
.schTime {
	margin-top: toRem(5);
    font-weight: 700;
}

.schP {
    color: $ap-text-gray-1;
    font-weight: 300;
}
.schLineBox {
	width: auto;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}
.schLine {
	width: toRem(70);
    height: toRem(1);
    margin: toRem(10) toRem(20) toRem(14);
    background: $ap-text-gray-3;
    text-align: center;
}
.schCtiWrap {
    margin-top: toRem(50);
}
.schTmWrap {
	//margin-top: toRem(60);
}
.schTmWrap.none {
	height: toRem(200);
	line-height: toRem(200);
	text-align: center;
	color: $ap-text-gray-1;
	border-bottom: toRem(1) solid $ap-line-gray;
}
.schTmWrap .tabBtn .btn {
	margin-right: toRem(30);
	padding: 0;
	position: relative;
	color: $ap-black;
	font-weight: 700;
}
.schTmWrap .tabBtn .btn:first-child.on {
	padding-left: 0;
}
.schTmWrap .tabBtn .btn.on {
	color: $ap-point-orange;
	background: inherit;
}
.schTmWrap .tabBtn .btn.on::after {
	content: '';
	width: 100%;
	height: toRem(2);
	display: block;
	position: absolute;
	bottom: toRem(-2);
	left: 0;
	background: $ap-point-orange;
}
.schTimeWrap {
	margin-top: toRem(60);
	border-bottom: toRem(2) solid $ap-line-black;
}
.schTimeWrap .schTimeHead {
	padding: toRem(10) 0;
	display: flex;
	align-items: center;
}
.schTimeWrap p {
	width: 33.33%;
	color: $ap-text-gray-1;
	text-align: left;
}
.schTimeBd {
	padding: toRem(36) 0;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: toRem(1) solid $ap-line-gray;
}
.schTimeBd .flNum {
	font-weight: 700;
}
.schTimeBd .flNum,
.schTimeBd .schTimeCont {
	width: 33.33%;
	text-align: left;
}
.timeWrap.tgLWrap {
	border-bottom: toRem(1) solid $ap-line-gray;
}
.timeWrap .tgList .tgBtn::after {
	content: '';
	width: toRem(20);
	height: toRem(20);
	position: absolute;
	right: toRem(20);
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_dropdown_arrow_able_up_black2.svg') no-repeat center;
	cursor: pointer;
}
.timeWrap .tgList.tglOn .tgBtn::after {
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_dropdown_arrow_able_down_black2.svg') no-repeat center;
}
.schTimeCont {
	width: auto;
	padding: toRem(10) 0;
	position: relative;
	display: flex;
	align-items: center;
}
.schTimeCont .schTimeDl {
	display: flex;
}
.schTimeCont .schTime {
	margin-top: 0;
	margin-left: toRem(10);
	font-size: toRem(20);
	font-family: 'Heebo'
}
.schTimeDay {
	padding: 0 toRem(10);
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.schTimeDay .schDay {
	padding: toRem(10) toRem(40);
	text-align: center;
}
.schTimeDay .schDay.tOd {
	background: $ap-line-gray-light;
}
.schTimeDay .schDay .flPl {
	width: toRem(13);
	height: toRem(18);
	margin: toRem(10) auto;
	background-color: $ap-text-gray-4;
	border-radius: toRem(20);
}
.schTimeDay .schDay .flPl.flOn {
	background-color: $ap-point-orange;
}
.dacWrap .secSlt .slctBox .slctCon.schCal {
	width: 650px;
	@include rwd('lg') {
		width: 100%;
	}
	@include rwd("sm") {
		width: -webkit-fill-available;
	}
}
.slctCon.schCal .daterangeWrap .calendarWrap .calendar .list > ul > li {
	height: toRem(40);
}
.dacWrap .slctCon.schCal .daterangeWrap .bottomArea {
	justify-content: flex-end;
}
@include rwd('lg'){
	.schWarp {
	    display: flex;
	    flex-direction: column;
	    align-items: center;
	}
	.dacWrap .slctCon.schCal .daterangeWrap .bottomArea {
		height: toRem(100);
		padding: toRem(20) toRem(25);
		display: block;
		justify-content: space-between;
	}
	.dacWrap .slctCon.schCal .daterangeWrap .bottomArea .taskButton {
		width: 100%;
		margin-top: toRem(10);
	}
}

@media only screen and (max-width: 999px) {
	.schTimeDay .schDay {
		padding: toRem(10) toRem(30);
	}
}
@include rwd('sm'){
	.schTimeWrap p {
		width: 30%;
	}
	.schTimeWrap p:first-child {
		text-align: left;
	}
	.schTimeWrap p:last-child {
		width: 40%;
	}
	.timeWrap .tgList .tgBtn::after {
		right: toRem(10);
	}
	.schTimeBd .schTimeCont {
		display: block;
	}
	.schTimeBd .flNum {
		font-size: 1.4rem;
	}
	.schTimeBd .flNum,
	.schTimeBd .schTimeCont {
		width: 30%;
	}
	.schTimeBd .schTimeCont:last-child {
		width: 40%;
	}
	.schTimeCont .schP {
		margin: 0 auto;
		font-size: 1.4rem;
	}
	.schTimeCont .schTime {
		margin-left: 0;
		font-size: toRem(16);
	}
	.schTime.ftO {
		margin-left: toRem(5);
	}
	.schTimeCont:last-child::after {
		top: 30%;
		right: 0;
	}
	.schTimeDay {
		padding: 0;
	}
	.schTimeDay .schDay {
		padding: toRem(10);
		font-size: toRem(12);
	}
	.dacWrap .slctCon.schCal .daterangeWrap .topArea {
		height: calc(100% - #{toRem(80)});
	}
	.slctCon.schCal .daterangeWrap .calendarWrap .calendar .list > ul > li {
		height: toRem(50);
	}
	.dacWrap .slctCon.schCal .daterangeWrap .bottomArea .taskButton {
		margin-top: toRem(5);
	}
}

@media only screen and (max-width: 479px) {
	.schTimeBd .flNum {
		padding-right: toRem(10);
		font-size: toRem(13);
	}
	.schTimeCont .schP {
		font-size: toRem(13);
	}
	.schTimeCont .schTime {
		font-size: toRem(14);
	}
	.schTimeDay .schDay {
		padding: toRem(10) toRem(2);
	}
}


/**********************
***** 코로나 안내센터 *****
**********************/


.covidTit {
	position: relative;
}
.covidImg {
	width: toRem(242);
	height: toRem(220);
	position: absolute;
	top: 0;
	right: 0;
	display: block;
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/illust/BGimg_illust.svg') no-repeat 0 0;
}
.covTitle {
    font-size: toRem(24);
    font-weight: 700;
}
.covFstBox,
.covSecBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: toRem(60);
}
.covFstBox {
	margin-top: toRem(60);
}
.covGidCont {
    width: calc(50% - #{toRem(15)});
    margin-right: toRem(30);
    display: flex;
    align-items: center;
}
.covGidCont:nth-child(even) {
    margin-right: 0;
}
.covSec {
    width: calc(100% - #{toRem(120)});
    margin-left: toRem(30);
}
.covSecTitle {
	margin-bottom: toRem(15);
    display: inline-block;
    color: $ap-text-black;
    font-size: toRem(20);
    font-weight: 700;
}
.covIcon {
    width: toRem(90);
    height: toRem(120);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: toRem(50);
    background-color: $ap-text-gray-6;
}
@include rwd('md'){
	.covidImg {
		display: none;
	}
	.covFstBox,
	.covSecBox {
		margin-bottom: 0;
		flex-wrap: wrap;
	}
	.covGidCont {
		width: 100%;
		margin-right: 0;
		margin-bottom: toRem(50);
		display: block;
	}
	.covIcon {
		margin: 0 auto;
		margin-bottom: toRem(15);
	}
	.covSec {
		width: 100%;
		margin-left: 0;
		text-align: center;
	}
	.covSecTitle {
		margin-bottom: 0;
	}
}
@include rwd('sm'){
	.covTitle {
		font-size: 2rem;
		line-height: 1.5em;
	}
	.covSecTitle {
		margin-bottom: toRem(10);
		font-size: 1.6rem;
	}
	.covSec p {
		line-height: 1.4em;
	}
}


/**********************
***** 프레미아 멤버스 *****
**********************/

/* 포인트 사용 */
.fltSmImg {
	width: toRem(18);
	height: toRem(18);
	margin: 0 toRem(20);
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_flight.svg')no-repeat;
	vertical-align: middle;
}
.fltSmImgWh {
	width: toRem(18);
	height: toRem(18);
	margin: 0 toRem(20);
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_flight_w.svg')no-repeat;
	vertical-align: middle;
}
.usePointWrap .tbWrap table tbody .text {
    width: calc(50% - #{toRem(40)});
    text-align: left;
    display: inline-block;
}
.tbExplain {
	font-size: 90%;
	text-align: right;
}
/* 포인트 적립 안내 */
.pointWrap .bannerBox {
	padding: toRem(45) toRem(200) toRem(45) toRem(45);
	border: toRem(1) solid $ap-text-gray-4;
	border-radius: toRem(12);
}
.pointWrap .bannerBox .pointIconWrap {
	text-align: center;
}
.pointWrap .premCount li div {
	display: flex;
	align-items: center;
}
.pointWrap .premCount li .line {
	width: toRem(1);
	height: toRem(14);
	margin: 0 toRem(10);
	background: $ap-text-gray-4;
}
.pointWrap .critWrap .bannerBox {
	padding: toRem(45);
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* background: url('https://com.airpremiacdn.net/resources/onepoint/images/premiaMembers/savePoint/pointbannerbg.png')no-repeat;
	background-position: top right; */
}
.pointWrap .critWrap .bannerBox .airfareIcon {
	width: toRem(100);
	height: toRem(64);
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/premiaMembers/savePoint/premiaMoney.svg')no-repeat;
	background-position: center;
}
.pointWrap .critWrap .bannerBox .saveIcon {
	width: toRem(100);
	height: toRem(64);
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/premiaMembers/savePoint/premiaPoint.svg')no-repeat;
	background-position: center;
}
.pointWrap .critWrap .bannerBox .contour {
	width: toRem(1);
	height: toRem(90);
	margin: 0 toRem(25) 0 toRem(35);
	display: block;
	background: $ap-text-gray-4;
}
.pointWrap .saveWrap .bannerBox {
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/premiaMembers/savePoint/illust_15_service_03.svg')no-repeat;
	background-position: bottom right;
}
.pointWrap .afterWrap .bannerBox {
	margin-top: toRem(100);
	display: flex;
	align-items: center;
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/premiaMembers/savePoint/worldBg.png')no-repeat;
	background-position: bottom right;
}
.pointWrap .afterWrap .bannerBox .pointIconWrap {
	margin-right: toRem(30);
}
.pointWrap .afterWrap .bannerBox .interIcon {
	width: toRem(100);
	height: toRem(100);
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/premiaMembers/savePoint/InternationalIcon.svg')no-repeat;
	background-position: center;
}
.pointWrap .afterWrap .btnWrap {
	width: toRem(300);
	margin: toRem(60) auto 0;
}

/* 멤버십 안내 */
.memWrap .bannerBox {
	margin-top: toRem(20);
	padding: toRem(45) toRem(500) toRem(45) toRem(45);
	border: toRem(1) solid $ap-text-gray-4;
	border-radius: toRem(12);
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/premiaMembers/grade/membersinfo.png')no-repeat;
	background-position: center right toRem(45);
}
.memWrap .bannerBox.fstBan {
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/premiaMembers/grade/bag.png')no-repeat;
	background-position: center right toRem(45);
}
.memWrap .bannerBox.sndBan {
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/premiaMembers/grade/free.png')no-repeat;
	background-position: center right toRem(45);
}
.memWrap .bannerBox.trdBan {
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/premiaMembers/grade/upgrade.png')no-repeat;
	background-position: center right toRem(45);
}
.memWrap .bannerBox .subSmTit:nth-of-type(2) {
	margin-top: toRem(20);
}
.gradeTb {
	margin-top: toRem(20);
	text-align: center;
}
.gradeTb .bgG {
	background: $ap-text-gray-5;
}
.gradeTb tr th,
.gradeTb tr td {
	padding: toRem(20) 0;
	vertical-align: middle;
}
.gradeTb thead tr th,
.gradeTb thead tr td {
	border-bottom: toRem(1) solid $ap-line-black;
}
.gradeTb tbody tr th,
.gradeTb tbody tr td {
	border-bottom: toRem(1) solid $ap-line-gray;
}
.gradeTb tbody tr th {
	text-align: left;
	font-weight: 700;
}
.gradeTb tbody tr td .bold {
	font-weight: 700;
}
.memWrap .spBanner {
	margin-top: toRem(100);
	padding: toRem(45) toRem(300) toRem(45) toRem(45);
	display: flex;
	align-items: flex-start;
	background-color: rgba(232, 221, 213, 0.5);
	background-image: url('https://com.airpremiacdn.net/resources/onepoint/images/premiaMembers/grade/why.png');
	background-repeat: no-repeat;
	background-position: center right toRem(45);
	border-radius: toRem(12);
}
.memWrap .spBanner .left {
	flex-basis: toRem(300);
	margin-right: toRem(40);
}
.memWrap .spBanner .left h3 {
	margin-bottom: toRem(15);
	font-weight: 700;
	font-size: toRem(48);
	color: $ap-point-orange;
}
.memWrap .spBanner .left p {
	margin-top: toRem(40);
	text-align: justify;
}
.memWrap .comContent .bannerBox .contents {
	margin-top: toRem(20);
}

.tbWrap table thead td.sb {
    border-right: toRem(1) solid $ap-point-blue-dark;
}
@include rwd('lg'){
	/* 포인트 적립 안내 */
	.pointWrap .critWrap .bannerBox {
		display: block;
	}
	.pointWrap .critWrap .bannerBox .flex {
		justify-content: flex-start;
	}
	.pointWrap .critWrap .bannerBox .flex:first-child {
		margin-bottom: toRem(40);
	}
	.pointWrap .critWrap .bannerBox .contour {
		display: none;
	}
	.pointWrap .afterWrap .btnWrap {
		width: 100%;
		max-width: toRem(300);
	}
	.pointWrap .premCount li div {
		display: block;
	}
	.pointWrap .premCount li .line {
		display: none;
	}

	/* 멤버십 안내 */
	.memWrap .bannerBox {
		padding: toRem(45) toRem(300) toRem(45) toRem(45);
		background: url('https://com.airpremiacdn.net/resources/onepoint/images/premiaMembers/grade/membersinfo.png')no-repeat;
		background-size: toRem(260);
		background-position: center right toRem(45);
	}
	.memWrap .spBanner {
		padding: toRem(25) toRem(300) toRem(45) toRem(45);
		display: block;
	}
	.memWrap .spBanner .left {
		margin-right: 0;
		margin-bottom: toRem(30);
	}
	.memWrap .spBanner .left p {
		margin-top: toRem(20);
	}
}

@media only screen and (max-width: 1223px) {
	/* 포인트 사용 */
	.fltSmImg {
		margin: 0 toRem(10);
	}
	.fltSmImgWh {
		margin: 0 toRem(10);
	}
}

@include rwd('sm'){
	/* 포인트 사용 */
	.fltSmImg {
		width: toRem(12);
		height: toRem(12);
		margin: 0 toRem(5);
		background-size: cover;
	}
	.fltSmImgWh {
		width: toRem(12);
		height: toRem(12);
		margin: 0 toRem(5);
		background-size: cover;
	}
	.usePointWrap .tbWrap table tbody .text {
	    width: calc(50% - #{toRem(17)});
	    text-align: left;
	    display: inline-block;
	}

	/* 포인트 적립 안내 */
	.pointWrap .critWrap .bannerBox {
		background: none;
	}
	.pointWrap .saveWrap .bannerBox {
		background: none;
	}
	.pointWrap .afterWrap .bannerBox {
	    margin-top: toRem(60);
		background: none;
	}
	.pointWrap .bannerBox {
		padding: toRem(25) toRem(25) toRem(25) toRem(15);
	}
	.pointWrap .critWrap .bannerBox .airfareIcon {
		width: toRem(75);
		height: toRem(48);
		background-size: cover;
	}
	.pointWrap .critWrap .bannerBox .saveIcon {
		width: toRem(75);
		height: toRem(48);
		background-size: contain;
	}
	.pointWrap .afterWrap .bannerBox .interIcon {
		width: toRem(75);
		height: toRem(48);
		background-size: contain;
	}
	.pointWrap .afterWrap .bannerBox .pointIconWrap {
		margin-right: toRem(20);
	}

	/* 멤버십 안내 */
	.memWrap .bannerBox {
		padding: toRem(25);
		background: none;
	}
	.memWrap .bannerBox.fstBan {
		background: none;
	}
	.memWrap .bannerBox.sndBan {
		background: none;
	}
	.memWrap .bannerBox.trdBan {
		background: none;
	}
	.gradeTb tr th,
	.gradeTb tr td {
		padding: toRem(10) toRem(5);
		font-size: toRem(14);
	}
	.memWrap .spBanner {
		padding: toRem(5) toRem(25) toRem(25) toRem(25);
		background-image: none;
	}
	.memWrap .spBanner .left {
		margin-right: 0;
		margin-bottom: toRem(30);
	}
	.memWrap .spBanner .left p {
		margin-top: toRem(20);
	}
}

@include rwd('xs'){
	/* 포인트 적립 안내 */
	.pointWrap .critWrap .bannerBox .flex {
		display: block;
	}
	.pointWrap .critWrap .bannerBox .pointIconWrap {
		margin-bottom: toRem(10);
	}
	.pointWrap .critWrap .bannerBox .airfareIcon {
		margin: 0 auto;
	}
	.pointWrap .critWrap .bannerBox .saveIcon {
		margin: 0 auto;
	}
	.pointWrap .afterWrap .bannerBox .interIcon {
		margin: 0 auto;
	}
}

/****************
**** 서비스안내 ****
****************/


/* 운송제한물품 */
.lmtInfo .red {
	color: $ap-red;
}
.lmtInfo .blue {
	color: $ap-solid-skyblue;
}
.tbDiv {
	width: 100%;
    display: flex;
    align-items: start;
    flex-wrap: wrap;
}
.tdDivnNW {
    flex-wrap: nowrap;
}
.tbDiv .tbUl {
	width: 50%;
	float: left;
	display: table-cell;
}
.tbDiv .lic1 {
	width: 20%;
}
.tbDiv .lic2 {
	width: 40%;
    height: fit-content;
}
.tbDiv .tbUl > li {
	width: 25%;
	display: table-cell;
	vertical-align: top;
	border-right: toRem(1) solid transparent;
	border-image: linear-gradient(rgb(50, 65, 88) toRem(51), rgb(221, 221, 221) toRem(51), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch;
}

.tbDiv .tbUl > li.last {
	border: 0;
}
.tbDiv ul li .tit {
	width: 100%;
	height: toRem(50);
	padding: toRem(10);
	color: $ap-white;
	font-weight: 500;
	letter-spacing: toRem(0.3);
	text-align: center;
	background: $ap-solid-darkblue;
	display: table;
}

.tbDiv ul.lb5m li .tit {
	height: toRem(110);
}
.tbDiv ul.lb5m li {
    border-image: linear-gradient(rgb(50, 65, 88) toRem(110), rgb(221, 221, 221) toRem(110), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch;
}

/* english */
.en .tbDiv .tbUl > li {
	border-image: linear-gradient(rgb(50, 65, 88) toRem(80), rgb(221, 221, 221) toRem(80), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch;
}
.en .tbDiv ul li .tit {
	height: toRem(80);
}
.en .tbDiv ul.lb5m li .tit {
	height: toRem(140);
}
.en .tbDiv ul.lb5m.lb3m li .tit {
	height: toRem(140);
}
.en .tbDiv ul.lb5m  > li {
	border-image: linear-gradient(rgb(50, 65, 88) toRem(140), rgb(221, 221, 221) toRem(140), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch;
}
.en .tbDiv ul.lb3m.lb5m  > li.last {
    border-right: none;
}
.tbDiv ul li .tit span {
	height: toRem(23);
	display: table-cell;
	vertical-align: middle;
}
.tbDiv ul li .imgBox {
	width: 100%;
	height: auto;
	padding: toRem(18) toRem(10);
	vertical-align: middle;
	text-align: center;
	border-bottom: toRem(1) solid $ap-text-gray-4;
}
.tbDiv ul li .conBox {
/* 	height: toRem(180); */
	padding: toRem(10);
}
.tbDiv ul li .conBox.hGtlw {
	height: toRem(163);
}
.tbDiv ul li .conBox.hGtmd {
	height: toRem(221);
}
.tbDiv ul li .conBox.hGtlg {
	height: toRem(308);
}
.en .tbDiv ul li .conBox {
	height: toRem(260);
}

.en .tbDiv ul.lic2 li .conBox {
	height: toRem(320);
}
.en .tbDiv ul.lic3 li .conBox {
	height: toRem(500);
}
.tbDiv .lb3m > li {
	vertical-align: top;
	border-right: toRem(1) solid transparent;
	border-image: linear-gradient(rgb(50, 65, 88) toRem(79), rgb(221, 221, 221) toRem(79), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch;
}
.en .tbDiv .lb3m > li {
	vertical-align: top;
	border-right: toRem(1) solid transparent;
	border-image: linear-gradient(rgb(50, 65, 88) toRem(110), rgb(221, 221, 221) toRem(110), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch;
}
.en .tbDiv ul.lb5m li .conBox {
    height: toRem(580);
}
.btnRule {
	margin: toRem(10) 0 0 toRem(10);
	display: flex;
	justify-content: flex-start;
}

@include rwd('lg'){

	.tbDiv ul li .conBox {
    height: toRem(250);
	}
	.tbDiv ul.lic2 li .conBox,
	.tbDiv ul li .conBox.hGtmd {
	    height: toRem(300);
	}

	.tbDiv ul.lb5m li .conBox {
	    height: toRem(440);
	}

	/* english */
	.en .tbDiv ul.lb5m li .tit,
	.en .tbDiv ul.lb5m.lb3m li .tit {
		height: toRem(200);
	}
	.en .tbDiv ul.lb5m > li {
	    border-image: linear-gradient(rgb(50, 65, 88) toRem(200), rgb(221, 221, 221) toRem(200), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch;
	}
	.en .tbDiv ul.lb5m li .conBox {
		height: toRem(800);
	}

	.en .tbDiv ul li .conBox {
		height: toRem(350);
	}
	.en .tbDiv ul.lic2 li .conBox {
		height: toRem(450);
	}
}
@include rwd('md'){
	.tdDivnNW {
	    flex-wrap: wrap;
	}
	.tbDiv .tbUl {
		width: 100%;
	}
	.tbDiv ul li .conBox {
		height: toRem(180);
	}
	.tbDiv .tbUl > li:last-child {
		border-right: 0;
	}
	.tbDiv ul.lic1 li .conBox {
		height: toRem(120);
	}
	.tbDiv ul.lic2 li .conBox,
	.tbDiv ul li .conBox.hGtmd {
		height: toRem(200);
	}

	.tbDiv ul.lb5m li .conBox {
	    height: toRem(280);
	}
	.tbDiv ul.lb5m li.last .conBox {
		height: toRem(100);
	}




	.en .tbDiv ul li .conBox,
	.en .tbDiv ul.lic2 li .conBox {
		height: toRem(240);
	}
	.en .tbDiv ul.lb3m.lb5m  > li.last {
	    border-right: none;
	}
	.en .tbDiv ul.lb5m > li:last-child {
		border-right: toRem(1) solid transparent;
	}
	.en .tbDiv ul.lb5m li .tit, .en .tbDiv ul.lb5m.lb3m li .tit {
    	height: toRem(135);
	}
	.en .tbDiv ul.lb5m > li {
	    border-image: linear-gradient(rgb(50, 65, 88) toRem(135), rgb(221, 221, 221) toRem(135), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch;
	}
	.en .tbDiv ul.lb5m.lb3m li.last .tit {
   	    height: toRem(80);
	}
	.en .tbDiv ul.lb5m li .conBox {
    	height: toRem(350);
	}
	.en .tbDiv ul li.last .conBox {
    	height: toRem(150);
	}
	.en .tbDiv ul.lb5m li.last .conBox {
    	height: toRem(150);
	}
}
@include rwd('md'){
	.tbDiv .tbUl {
		width: 100%;
	}
	.tbDiv ul li .conBox {
		height: toRem(180);
	}
	.tbDiv .tbUl .listGlobal > li {
		font-size: toRem(13);
	}
	.Liquid img {
		width: 100%;
	}
	.tbDiv ul.lb5m li .tit {
	    height: toRem(80);
	}
	.tbDiv ul.lb5m li {
	    border-image: linear-gradient(rgb(50, 65, 88) toRem(80), rgb(221, 221, 221) toRem(80), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch;
	}
	.tbDiv ul.lb5m li .conBox {
		height: toRem(250);
	}
	.en .tbDiv ul li .conBox {
	    height: toRem(300);
	}
	.en .tbDiv ul.lic2 li .conBox {
	    height: toRem(300);
	}
	.en .tbDiv ul.lb5m li .conBox {
    	height: toRem(450);
	}

	.en .tbDiv ul.lb5m.lb3m li.last .conBox,
	.en .tbDiv ul.lic1 li.last .conBox  {
	    height: toRem(100);
	}
}
@include rwd('xs'){
	.tbDiv ul li .conBox {
		height: toRem(200);
	}
	.tbDiv ul.lic2 li .conBox {
		height: toRem(280);
	}
	.tbDiv ul.lb2m li .tit {
		height: toRem(70);
	}
	.tbDiv ul.lb2m > li {
	    border-image: linear-gradient(rgb(50, 65, 88) toRem(70), rgb(221, 221, 221) toRem(70), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch;
	}
	.tbDiv ul.lb5m li .tit {
		height: toRem(90);
	}
	.tbDiv ul.lb5m li {
	    border-image: linear-gradient(rgb(50, 65, 88) toRem(90), rgb(221, 221, 221) toRem(90), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch;
	}
	.tbDiv ul.lb5m.lb3m li.last .tit {
		height: toRem(80);
	}
	.tbDiv ul.lb5m li .conBox {
		height: toRem(280);
	}

	.en .tbDiv .tbUl > li {
		border-image: linear-gradient(rgb(50, 65, 88) toRem(80), rgb(221, 221, 221) toRem(80), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch
	}
	.en .tbDiv .lb2m > li {
		border-image: linear-gradient(rgb(50, 65, 88) toRem(90), rgb(221, 221, 221) toRem(90), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch;
	}
	.en .tbDiv ul.lb2m li .tit {
		height: toRem(90);
	}
	.en .tbDiv ul.lb3m > li {
		border-image: linear-gradient(rgb(50, 65, 88) toRem(110), rgb(221, 221, 221) toRem(110), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch;
	}
	.en .tbDiv ul.lb5m li .tit {
		height: toRem(180);
	}
	.en .tbDiv ul.lb5m > li {
		width: 50%;
		border-image: linear-gradient(rgb(50, 65, 88) toRem(180), rgb(221, 221, 221) toRem(180), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch;
	}
	.en .tbDiv ul.lb5m li .tit, .en .tbDiv ul.lb5m.lb3m li .tit {
	    height: toRem(155);
	}
	.en .tbDiv ul.lb5m > li {
	    border-image: linear-gradient(rgb(50, 65, 88) toRem(155), rgb(221, 221, 221) toRem(155), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch;
	}
	.en .tbDiv ul.lb5m li .conBox {
	    height: toRem(550);
	}
	.en .tbDiv ul li .conBox,
	.en .tbDiv ul.lic2 li .conBox {
	    height: toRem(350);
	}
	.en .tbDiv ul.lb5m.lb3m li.last .conBox,
	.en .tbDiv ul.lic1 li.last .conBox {
	    height: toRem(150);
	}

}
@media only screen and (max-width: 359px) {

	.tbDiv ul li .conBox {
		height: toRem(230);
	}
	.tbDiv ul.lic2 li .conBox {
		height: toRem(310);
	}
	.tbDiv ul.lb5m li .conBox {
	    height: toRem(320);
	}

	.en .tbDiv ul.lb5m li .conBox {
		height: toRem(750);
	}
	.en .tbDiv ul.lb2m li .tit {
		height: toRem(120);
	}
	.en .tbDiv .lb2m > li {
	    border-image: linear-gradient(rgb(50, 65, 88) toRem(120), rgb(221, 221, 221) toRem(120), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch;
	}
	.en .tbDiv ul li .conBox, .en .tbDiv ul.lic2 li .conBox {
    	height: toRem(450);
	}
	.en .tbDiv ul.lb5m li .tit, .en .tbDiv ul.lb5m.lb3m li .tit {
		height: toRem(175);
	}
	.en .tbDiv ul.lb5m > li {
	    border-image: linear-gradient(rgb(50, 65, 88) toRem(175), rgb(221, 221, 221) toRem(175), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch;
	}
	.en .tbDiv ul.lb5m.lb3m li.last .tit {
	    height: toRem(100);
	}
	.en .tbDiv ul.lb5m > li.last {
	    border-image: linear-gradient(rgb(50, 65, 88) toRem(100), rgb(221, 221, 221) toRem(100), rgb(221, 221, 221) 100%) 1 / 1 / 0 stretch;
	}
}

/****************
****** 기타 ******
****************/


/* 항공교통이용자 피해구제계획 */
.termStepWrap {
    position: relative;
}
.termStepWrap:before,
.termStepWrap:after {
	content: "";
	height: toRem(2);
	position: absolute;
	left: toRem(10);
	right: toRem(10);
	background: $ap-solid-darkblue;
	z-index: -1;
}
.termStepWrap:before {
	top: toRem(100);
}
.termStepWrap:after {
	top: toRem(341);
}
.termStepWrap ul {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.termStepWrap .arrow {
	height: toRem(2);
	position: absolute;
	top: toRem(210);
	left: toRem(190);
	right: calc(50% + #{toRem(90)});
	transform: rotate(-25deg);
	background: $ap-solid-darkblue;
}
.termStepWrap .arrow:before {
    content: "";
    width: toRem(20);
    height: toRem(10);
    position: absolute;
    left: toRem(-10);
    bottom: toRem(-4);
    display: block;
    transform: rotate(-90deg);
    background: url("https://com.airpremiacdn.net/resources/onepoint/images/home/footer_triangle.png") no-repeat;
}
.termStepWrap .step {
  width: toRem(200);
  height: toRem(200);
  margin-bottom: toRem(40);
  padding: toRem(15);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $ap-white;
  text-align: center;
  background: $ap-solid-darkblue;
  border-radius: 50%;
}
.termStepWrap .step.s3:before {
	content: "";
	width: toRem(2);
	height: toRem(60);
	position: absolute;
	bottom: toRem(-50);
	background: $ap-solid-darkblue;
	z-index: -1;
}
.termStepWrap .step:last-child {
    margin-bottom: 0;
}
.termStepWrap .title {
    padding-bottom: toRem(10);
}
.termStepWrap i {
    width: calc(100% - #{toRem(30)});
    position: absolute;
}
.termStepWrap .num {
    top: toRem(15);
}
.termStepWrap .user {
	bottom: toRem(20);
	font-size: toRem(15);
	opacity: 0.6;
}

@include rwd('sm'){
	.termStepWrap:before {
	    top: toRem(70);
	}
	.termStepWrap:after {
    	top: toRem(251);
	}
	.termStepWrap .step {
	    width: toRem(140);
	    height: toRem(140);
	}
	.termStepWrap .arrow {
	    left: toRem(120);
	    right: calc(50% + #{toRem(40)});
	    top: toRem(160);
	    transform: rotate(-50deg);
	}
	.termStepWrap .num {
	    top: toRem(5);
	}
	.termStepWrap .user {
	    bottom: toRem(10);
	    font-size: 1.3rem;
	}
}


@media only screen and (max-width: 599px) {
	.termStepWrap:before {
	    display: none;
	}
	.termStepWrap:after {
	    top: auto;
	    bottom: toRem(59);
	}
	.termStepWrap ul {
    	display: block;
	}
	.termStepWrap ul > li {
        margin-bottom: toRem(20);
        display: flex;
        justify-content: space-between;
    }
	.termStepWrap .arrow {
	    left: toRem(110);
	    right: toRem(120);
	    top: toRem(120);
	    transform: rotate(-30deg);
	}
    .termStepWrap .arrow:before {
      left: auto;
      right: toRem(-10);
      transform: rotate(90deg);
    }
	.termStepWrap .step {
	    width: toRem(120);
	    height: toRem(120);
	    margin-bottom: 0;
	    padding: toRem(5);
	}
	.termStepWrap .step.s3:before {
        display: none;
    }
    .termStepWrap .step.s1:after,
    .termStepWrap .step.s2:after,
    .termStepWrap .step.s6:after,
    .termStepWrap .step.s5:after {
		content: "";
		width: toRem(2);
		height: 100%;
		top: toRem(59);
		position: absolute;
		display: block;
		background: $ap-solid-darkblue;
		z-index: -1;
    }
	.termStepWrap .title {
	    font-size: 1.3rem;
	    letter-spacing: toRem(-0.7);
	    line-height: 1.4em;
	}
}

@include rwd('xs'){
    .termStepWrap .arrow {
        right: toRem(110);
        top: toRem(130);
        transform: rotate(-40deg);
    }
}


/************************
***** 도움이 필요한 손님 *****
*************************/

.supCont {
    border-top: toRem(2) solid $ap-text-black;
}
.infTit {
    font-size: toRem(26);
    font-weight: 700;
}
.infSubTit {
    font-size: toRem(20);
    font-weight: 700;
}
.stroller {
    width: toRem(94);
    height: toRem(94);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(https://com.airpremiacdn.net/resources/onepoint/images/support/stroller.png);
}
.baggageStrollerB {
    width: toRem(160);
    height: toRem(160);
    margin-left: toRem(10);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(https://com.airpremiacdn.net/resources/onepoint/images/support/baggageStroller_b.png);
    background-repeat: no-repeat;
}
span.bagstr {
    margin-top: toRem(24);
    margin-left: toRem(-74);
    font-size: toRem(16);
    line-height: 1.6em;
    text-align: center;
}
.infSTtitle {
    margin-bottom: toRem(10);
    font-size: toRem(18);
    font-weight: 500;
}
.prgOrang {
	color: $ap-point-orange;
}
.helpPd50 {
    padding-left: toRem(50);
    padding-right: toRem(50);
}
.petFlexBox {
    margin-top: toRem(20);
    display: flex;
    align-items: baseline;
}
.petSubTit {
	margin-bottom: toRem(10);
	font-size: toRem(16);
    font-weight: 700;
}
.petTxtRight {
    width: 100%;
    height: auto;
    margin-left: toRem(10);
    display: block;
    flex: 1;
}
@include rwd('sm'){
	.infTit {
		font-size: toRem(18);
	}
	.infSubTit {
		font-size: toRem(16);
	}
	.infSTtitle {
		font-size: toRem(14);
	}
	.stroller {
		width: toRem(60);
		height: toRem(60);
		background-size: toRem(60);
	}
	.baggageStrollerB {
        width: toRem(120);
        height: toRem(120);
        background-size: toRem(120);
	}
	span.bagstr {
        margin-top: toRem(20);
        margin-left: toRem(-58);
        font-size: toRem(13);
	}
	.petFlexBox .badgeArrow {
		padding: toRem(1) toRem(8);
		line-height: 1.3em;
	}
	.petFlexBox .badgeArrow p,
	.petSubTit,
	.petTxtRight p {
		font-size: toRem(14);
	}
}
@include rwd('xs'){
	.petFlexBox {
		display: block;
	}
	.petTxtRight {
	    margin-left: 0;
	    margin-top: toRem(10);
	}
}


/******************
***** 회원 가입 *****
******************/

.AuthWrapper.signup {
	max-width: toRem(900) !important;
}
.fortselBox {
    width: toRem(280);
	height: toRem(240);
	margin-right: toRem(30);
	border-radius: toRem(12);
	border: solid toRem(1) $ap-text-gray-4;
}
.fortselBox:last-of-type {
	margin-right: 0;
}
.fortselBox:hover {
	border: solid toRem(1) $ap-solid-orange;
}
.sigIcon {
    width: toRem(66);
    height: toRem(90);
	margin-right: toRem(30);
}
.sigBoy {
    background: url(https://com.airpremiacdn.net/resources/onepoint/images/illust/img_profile_man_01_dormancy.svg) no-repeat;
}
.sigBoy.on,
.sigmenBtn:hover .sigBoy {
    background-image: url(https://com.airpremiacdn.net/resources/onepoint/images/illust/img_profile_man_01.svg);
}
.sigMan {
    background: url(https://com.airpremiacdn.net/resources/onepoint/images/illust/img_profile_man_02_dormancy.svg) no-repeat;
}
.sigMan.on,
.sigmenBtn:hover .sigMan {
    background-image: url(https://com.airpremiacdn.net/resources/onepoint/images/illust/img_profile_man_02.svg);
}
.sigWomen {
	background: url(https://com.airpremiacdn.net/resources/onepoint/images/illust/img_profile_woman_02_dormancy.svg) no-repeat;
}
.sigWomen.on,
.sigWomenBtn:hover .sigWomen {
    background-image: url(https://com.airpremiacdn.net/resources/onepoint/images/illust/img_profile_woman_02.svg);
}
.sigFlex {
    display: flex;
    align-items: center;
    justify-content: center;
}


/* 회원가입 팝업 */
.signupScs {
    width: toRem(460);
    background: $ap-white url('https://com.airpremiacdn.net/resources/onepoint/images/seq_join_fin_pc.gif')no-repeat center;
}
.signupScs.bPopWrap .popTit {
    padding: toRem(50) toRem(50) toRem(20) toRem(50);
}
.signupScs .popTit p .lc {
    display: block;
}
.signupScs .popScr {
    padding-bottom: toRem(100);
}
.signupScs .popScr p {
    color: $ap-solid-skyblue;
    font-size: 1.4rem;
    font-weight: 600;
}

@include rwd('sm'){
    .AuthWrapper.signup > .flex {
    	display: block;
    }
    .fortselBox {
    	width: 100%;
    	height: toRem(100);
    	margin-right: 0;
    	margin-bottom: toRem(20);
    	padding: 0 toRem(25);
    }
    .sigFlex {
    	justify-content: flex-start;
    }
    .sigIcon {
    	width: toRem(44);
    	height: toRem(60);
    	margin-bottom: 0;
    	background-size: toRem(44) toRem(60);
    }
    .signupScs {
        width: 100%;
        background-position: right toRem(-100);
    }
    .signupScs .popTit p .lc {
        display: inline-block;
    }
    .signupScs.bPopWrap .popTit {
        padding: toRem(25) toRem(25) toRem(10);
    }
}
@include rwd('xs'){
    .signupScs {
        background-position: toRem(-100) toRem(-80);
    }
}


/****************
**** 좌석 안내 ****
****************/
.infoTitWrap {
	padding-bottom: toRem(60);
	align-items: flex-end;
}
.info-cont h3 {
	margin: toRem(20) 0;
	font-size: toRem(40);
	letter-spacing: toRem(-0.6);
}
.info-cont p {
	margin-bottom: toRem(10);
}
.info-pic.economy img {
	width: toRem(377);
}
.info-pic.premia img {
	width: toRem(492);
}
.seatImgWrap {
	margin-top: toRem(60);
	margin-bottom: toRem(20);
}
.seatImgWrap img {
	width: 48%;
}
.seatClass {
	margin-top: toRem(100);
	padding: toRem(40);
	background: rgb(243, 238, 234);
	border-radius: toRem(12);
}
.seatClass h5 {
	font-size: toRem(48);
	line-height: 1.3em;
}
.seatClass .illustBox {
	margin: toRem(30) 0 toRem(20);
}
.seatClass .illustBox .illust {
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
}
.seatClass .illust li {
	width: toRem(150);
	margin: toRem(20);
	display: flex;
	flex-direction: column;
	align-items: center;
}
.seatClass .illust li .imgBox {
	width: toRem(150);
	height: toRem(150);
	padding: toRem(10);
	display: flex;
	justify-content: center;
	background: rgb(251, 248, 245);
	border-radius: toRem(10);
}
.seatClass .illust li .imgBox img {
	width: toRem(130);
}
.seatClass .illust.size90 {
	margin-top: 0;
}
.seatClass .illust.size90 li .imgBox img {
	width: toRem(90);
	margin: toRem(30);
}
.seatClass .illust li p {
	margin-top: toRem(20);
	text-align: center;
}
.vr360Wrap {
	margin-top: toRem(100);
}
.vr360Wrap p {
	margin-bottom: toRem(10);
}
.vr360Wrap .movie {
	width: 100%;
	height: auto;
	margin-top: toRem(30);
}
.vr360Wrap .movie  iframe {
	width: 100%;
	height: toRem(700);
	border: 0;
}

@include rwd('sm'){
	.infoTitWrap {
		padding-bottom: toRem(20);
		display: block;
	}
	.info-cont h3 {
		font-size: toRem(26);
	}
	.info-pic {
		margin-top: toRem(20);
		text-align: center;
	}
	.seatClass h5 {
		font-size: toRem(30);
	}
	.seatClass .illustBox {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}
	.seatClass .illustBox .illust {
		min-height: toRem(690);
		display: block;
	}
	.seatClass .illust li p {
		min-height: toRem(50);
	}
	.info-pic.economy img,
	.info-pic.premia img {
		max-width: 80%;
	}
}
@include rwd('xs'){
	.info-pic.economy img,
	.info-pic.premia img {
		max-width: 100%;
	}
	.vr360Wrap .movie  iframe {
		height: toRem(300);
	}
}

/****************
**** 공항이동 서비스 ****
****************/
.mvCont .mvTop {
	height: toRem(165);
	padding: toRem(40) toRem(30) toRem(30);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.mvTop .tit {
	color: $ap-text-black;
	font-size: toRem(36);
	font-weight: 700;
	letter-spacing: toRem(-0.3);
}
.mvTop .subTit {
	padding: toRem(20) 0;
	color: $ap-text-gray-1;
	font-size: toRem(16);
	font-weight: 400;
}
.mvMdl {
	height: calc(100vh - #{toRem(240)});
	overflow-y: auto;
	overflow-x: hidden;
	border-top: toRem(1) solid $ap-text-gray-3;
}
.mvMdl .inContWrap {
	width: 100%;
	height: 100%;
	display: flex;
}
.mvMdl .inContWrap .lft {
	width: toRem(250);
	padding: toRem(30);
	overflow-y: auto;
	border-top: none;
}
.inContWrap .btnWrap {
	min-height: calc(100% - #{toRem(225)});
}
.mvMdl .inContWrap .rgt {
	width: calc(100% - #{toRem(250)});
	padding: toRem(30) toRem(50);
	overflow-y: auto;
	overflow-x: hidden;
	border-left: toRem(1) solid $ap-text-gray-3;
}
.mvMdl .inContWrap .rgt .rgtContit {
	font-size: toRem(20);
	font-weight: 500;
}
.mvMdl .inContWrap .rgt .rgtsCon {
	margin-bottom: toRem(15);
}
.mvMdl .inContWrap .rgt .chkBox {
	display: block;
}
.mvCont .mvBottom {
	width: toRem(800);
	padding: toRem(15) toRem(30);
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	bottom: 0;
	background: $ap-white;
	border-top: toRem(1) solid $ap-text-gray-3;
	z-index: 1;
}
.mvCont .mvBottom .prcWrapper {
	width: unset;
	height: unset;
	display: flex;
	align-items: center;
}
.mvCont .mvBottom .taskButton {
	width: toRem(160);
	height: toRem(50);
}
@include rwd('lg'){
	.mvView,
	.mvCont,
	.mvCont .mvBottom {
		width: 100%;
	}
}
@include rwd('sm'){
	.mvTop .tit {
		font-size: toRem(26);
	}
	.mvMdl .inContWrap {
		flex-direction: column;
	}
	.mvMdl .inContWrap .lft {
		width: 100%;
		padding: toRem(30) toRem(25);
		overflow: visible;
		border-bottom: toRem(1) solid $ap-text-gray-3;
	}
	.mvMdl .inContWrap .rgt {
		width: 100%;
		padding: toRem(30);
		overflow: visible;
		border-left: 0;
	}
	.mvCont .mvBottom {
		display: block;
	}
	.mvBottom .prcWrapper .prcRgt {
		width: 100%;
		margin: 0;
		margin-bottom: toRem(20);
		justify-content: space-between;
	}
	.mvBottom .prcWrapper .prcRgt h3 {
		font-size: 1.8rem;
	}
	.mvBottom .btnsWrapper .taskButton {
		width: 100%;
	}
}
@include rwd('xs'){
	.mvMdl .btnBox {
		margin-top: toRem(5);
	}
}


/****************
**** 회원탈퇴 ***
****************/

.mdSub {
	margin-top: toRem(10);
	font-size: 1.6rem;
    line-height: 1.8em;
}
.mdInputWrap {
    margin-top: toRem(100);
    padding-bottom: toRem(60);
    border-top: toRem(2) solid $ap-line-black;
    border-bottom: toRem(2) solid $ap-line-black;
}
.mdInput {
    padding: toRem(100) 0;
    display: flex;
    border-bottom: toRem(1) solid $ap-text-gray-4;
}
.mdInput:last-child {
    padding-bottom: 0;
    border-bottom: 0;
}
.mdLabel {
    margin-right: toRem(100);
    display: flex;
    flex-direction: column;
    flex-basis: auto !important;
}
.mdInput .mdLabel h3 {
    font-size: 2.6rem;
    font-weight: 700;
}
.mdInput .mdLabel p,
.mdInput .mdLabel p span{
    font-size: 2rem;
    font-weight: 700;
}
.mdLabel.mdinfo {
    margin-right: toRem(210);
}
.mdWrap {
    flex: 3 1 0%;
}
.mdWrap .row {
    padding: toRem(20) 0;
    position: relative;
    display: flex;
    align-items: center;
    letter-spacing: toRem(-0.3);
    border-bottom: toRem(1) solid $ap-text-gray-4;
}
.mdWrap .row .label {
    width: toRem(160);
}
.mdWrap .row .value {
    padding-left: toRem(40);
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 1 0%;
}
.listGlobal.mdLst > li:first-child {
    margin-top: 0;
}
.listGlobal.mdLst > li {
    margin-top: toRem(5);
}
.mdinfoInput {
    padding: toRem(60) 0;
}
.mdwdwrap {
	flex: 1 1 0%;
}
.mdwdwrap .row .value {
    font-weight: 400;
}

.mdchkWrap {
    padding-top: toRem(60);
}
@include rwd('sm'){
	.mdSub {
    	font-size: 1.4rem;
	}
	.mdInputWrap {
		margin: 0;
	    padding-bottom: toRem(30);
	}
	.mdInput {
		padding: toRem(30) 0;
		flex-direction: column;
		border-bottom: 0;
	}
	.mdInput .mdLabel {
		margin-right: 0;
		margin-bottom: toRem(20);
	}
	.mdInput .inputWrap {
		flex: 1 1 auto;
	}
	.mdInput .infoTtl h3 {
		font-size: 1.8rem;
	}
	.mdWrap {
	    margin-top: toRem(20);
	}
	.mdInput .mdLabel h3 {
		font-size: 1.8rem;
	}
	.mdchkWrap {
    	padding-top: toRem(30);
	}
}

/****************
*** 사이트맵 ***
****************/
.siteTit {
	padding-bottom: toRem(60);
	position: relative;
	border-bottom: toRem(2) solid $ap-line-black;
}
.siteTit:after {
	content: "";
	width: toRem(202);
	height: toRem(134);
	display: block;
	position: absolute;
	right: 0;
	bottom: 0;
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/illust/illust_sitemap.svg') no-repeat;
	background-size: cover;
}
.siteWrap {
	display: flex;
	flex-basis: 50%;
	flex-wrap: wrap;
	justify-content: space-between;
}
.siteWrap .list {
	margin-right: toRem(60);
	margin-bottom: toRem(60);
	flex-basis: toRem(500);
	display: flex;
	justify-content: space-between;
}
.siteWrap .list .mapList {
	width: toRem(260);
	margin-bottom: toRem(10);
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: toRem(1) solid transparent;
}
.siteWrap .list .mapList:last-child {
	margin-bottom: 0;
}
.siteWrap .list .mapList:hover {
	border-bottom: toRem(1) solid $ap-solid-orange;
	color: $ap-solid-orange;
}
.siteWrap .service {
	margin-bottom: toRem(60);
	padding-top: toRem(60);
	display: flex;
	border-top: toRem(1) solid $ap-line-gray;
	border-bottom: toRem(1) solid $ap-line-gray;
}
.siteWrap .service .subMdTit {
	flex-basis: toRem(240);
}
.siteWrap .service .serWrap {
	flex-basis: calc( 100% - #{toRem(240)});
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.siteWrap .service .serWrap .list {
	display: block;
	flex-basis: auto;
}
.siteWrap .service .serWrap .list .subSmTit {
	margin-bottom: toRem(20);
	padding-bottom: toRem(15);
	border-bottom: toRem(1) solid $ap-line-gray;
}
@include rwd('lg'){
	.siteWrap .list {
		display: block;
		flex-basis: toRem(260);
	}
	.siteWrap .list .subMdTit {
		margin-bottom: toRem(20);
	}
	.siteWrap .service {
		display: block;
	}
	.siteWrap .service .subMdTit {
		margin-bottom: toRem(20);
	}
}
@include rwd('sm'){
	.siteTit {
		padding-bottom: toRem(20);
	}
	.siteTit:after {
		content: "";
		width: toRem(112);
		height: toRem(785);
	}
	.siteWrap {
		display: block;
	}
	.siteWrap .list {
		width: 100%;
		margin: 0 0 toRem(40) 0;
	}
	.siteWrap .list .subMdTit {
		padding-bottom: toRem(15);
		border-bottom: toRem(1) solid $ap-line-gray;
	}
	.siteWrap .list .maplist {
		width: 100%;
	}
	.siteWrap .service .subSmTit {
		color: $ap-text-gray-1;
		font-weight: 400;
	}
	.siteWrap .service .serWrap .list:last-child {
		margin-bottom: 0;
	}
}


/* 체크인 > 온라인체크인 */
.description.chkOndes {
    margin-top: toRem(10);
    padding: 0;
}
.chkOnCont {
    margin-top: toRem(120);
    border-top: none;
    border-bottom: none;
}
.chkOnCont .infoInput {
	justify-content: space-between;
}

.chkOnCont .infoInput .infoTtl {
    flex-basis: inherit;
}

.infoInput.chkTtl .infoTtl {
    width: calc(100% - #{toRem(860)});
}

.chkOnWrap .cttWrapper {
    width: toRem(800);
}
.chkOnWrap .row .value {
    padding-left: 0;
}
.cttWrapper .row .value.tiket::before {
	background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_ticket.svg) no-repeat center center;
}
.cttWrapper .row .value.boarding::before {
	background: url(https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_flight.svg) no-repeat center center;
}

.chkOnWrap .cttWrapper.inputWrap + .cttWrapper.inputWrap {
    padding-top: toRem(60);
}
.chkOnTit {
	margin-bottom: toRem(30);
}
@include rwd('lg'){
	.chkOnCont .infoInput{
	    flex-direction: column;
	}
		.chkOnWrap .cttWrapper.inputWrap {
	    margin-top: toRem(60);
	}
	.chkOnCont .infoInput .infoTtl {
		flex-basis: inherit;
	}
	.chkOnWrap .cttWrapper {
	    width: 100%;
	}
	.chkOnWrap .cttWrapper.inputWrap + .cttWrapper.inputWrap {
		padding-top: 0;
	}
}

@include rwd('sm'){
	.chkOnCont {
	    margin-top: toRem(40);
	}
	.chkOnWrap .inputWrap .row {
	    flex-direction: row;
	}

	.chkOnWrap .inputWrap .row {
		margin: 0;
	}
}

/****************
*** 온라인 체크인 ***
****************/

/* 온라인 체크인 (로그인의 경우) */

.chkComTit {
    margin-bottom: toRem(80);
}

.onlineChkCard {
    margin-bottom: toRem(10);
    padding: toRem(30);
    border-radius: toRem(12);
    border: solid toRem(1) $ap-text-gray-4;
    background-color: $ap-white;
}
.onChkTop {
    display: flex;
    justify-content: space-between;
}
.onChkBot{
    padding-top: toRem(50);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.onChkTop .right {
    position: relative;
    right: 0;
    top: toRem(2);
}
.onChkTitWrap {
    display: flex;
    align-items: center;
    font-size: toRem(24);
    font-weight: 700;
    letter-spacing: toRem(-0.4);
}
.onChkTitWrap .connectionMark {
	width: toRem(22);
	height: toRem(22);
    margin: 0 toRem(10);
    display: block;
    position: relative;
    top: toRem(1);
    font-weight: 400;
   	background: url(https://com.airpremiacdn.net/resources/onepoint/images/arrow/arrow_one_way.svg) no-repeat center center;
}
.onChkPaxInfo {
    padding-top: toRem(5);
    font-weight: 700;
    letter-spacing: toRem(-0.28);
    color: $ap-text-gray-2;
}
.textLinkBtn {
    display: flex;
    align-items: center;
}
.textLinkBtn span {
    font-weight: 700;
    letter-spacing: toRem(-0.26);
    text-align: right;
}
.textLinkBtn .arrowWrapper {
    position: relative;
    margin-left: toRem(-2);
    margin-top: toRem(1);
}
.textLinkBtn .arrowWrapper div {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    flex-direction: column;
    cursor: pointer;
    transform: translateY(#{toRem(1)});
}
.textLinkBtn .arrowWrapper div i {
    width: toRem(16);
    height: toRem(16);
    margin-left: toRem(5);
   	background: url(https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_dropdown_arrow_able_up_black2.svg) no-repeat center center;
    transform: rotate(-90deg);
}
.onChkBot .infosWrap {
    display: flex;
}
.onChkBot .infosWrap .info + .info {
    padding-left: toRem(60);
}
.onChkBot .infosWrap .info .label {
    letter-spacing: toRem(-0.26);
}
.onChkBot .infosWrap .info .value {
    padding-top: toRem(10);
    font-weight: 700;
    letter-spacing: toRem(-0.36);
}
.onChkBot .BtnWrap {
    width: toRem(174);
    height: toRem(50);
}
/* 온라인 체크인 no Data */
.noDataOnlineChkCard {
	height: toRem(215);
    position: relative;
}
.noDataOnlineChkCard  .onChkTitWrap {
    font-size: toRem(36);
}
.noDataOnlineChkCard .onChkPaxInfo {
    margin-top: toRem(20);
}
.onChkimage {
    position: absolute;
    right: toRem(45);
    bottom: 0;
}
.onChkimage i {
    width: toRem(164);
    height: toRem(190);
	background: url(https://com.airpremiacdn.net/resources/onepoint/images/illust/img_mobilecheck.svg) no-repeat;
    background-size: contain;
}
@include rwd('sm'){
	.onChkTitWrap {
	    font-size: toRem(20);
	}
	.onChkPaxInfo {
	    padding-top: toRem(10);
	    font-size: toRem(14);
	}
	.onChkBot {
	    flex-direction: column;
    	align-items: baseline;
	}
	.onChkBot .infosWrap {
	    display: block;
	}
	.onChkBot .infosWrap .info + .info {
	    margin-top: toRem(20);
	    padding-left: 0;
	}
	.onChkBot .infosWrap .info .label,
	.onChkBot .infosWrap .info .value {
	    font-size: toRem(14);
	}
	.onChkBot .BtnWrap {
	    width: toRem(300);
    	margin: toRem(30) auto 0;
	    font-size: toRem(14);
	}
	.noDataOnlineChkCard  .onChkTitWrap {
	    font-size: toRem(24);
	}
	.noDataOnlineChkCard .onChkPaxInfo {
	    margin-top: toRem(10);
	}
	.onChkimage {
		right: toRem(20);
	}
	.onChkimage i {
        width: toRem(112);
        height: toRem(130);
	}
}
@media only screen and (max-width: 479px) {
	.onChkTop {
		display: block;
	}
	.textLinkBtn {
		margin-left: auto;
	}
}
@include rwd('xs'){
	.onChkTitWrap {
    	align-items: baseline;
	    flex-direction: column;
	}
	.onChkTitWrap .connectionMark {
    	margin: toRem(5) 0;
	    transform: rotate(90deg);
	}
	.onChkBot .BtnWrap {
	    width: 100%;
	}
}

/* 체크인 > 온라인체크인 > 온라인체크인신청 */

.applyWrap {
    margin-bottom: toRem(30);
}
.applyWrap:last-child {
    margin-bottom: 0;
}
.applyWrap .paxFormPanel {
    height: toRem(110);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid toRem(1) $ap-line-gray;
}

.applyWrap .labelWrap {
    padding-top: toRem(10);
    display: flex;
    letter-spacing: toRem(-0.28);
}

.applyWrap .left .name {
    font-family: 'Premia','Pretendard JP',sans-serif;
    font-weight: 700;
    font-size: 2.4rem;
    letter-spacing: toRem(-0.48);
}

.applyWrap .labelWrap .gender:before {
    width: toRem(1);
    height: toRem(11);
    content: '';
    position: absolute;
    top: toRem(2);
    left: toRem(6.7);
    background-color: $ap-text-gray-4;
}

.applyWrap .labelWrap .gender {
    padding-left: toRem(13);
    position: relative;
}
.applyWrap .right {
    display: flex;
    align-items: center;
    color: $ap-text-black;
    text-align: right;
    font-weight: 700;
    letter-spacing: toRem(-0.32);
    cursor: pointer;
}

.applyWrap .arrowWrap {
	padding-left: toRem(20);
}

.applyWrap .arrowWrap i {
    width: toRem(20);
    height: toRem(20);
    display: flex;
    align-items: center;
    flex-direction: column;
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/arrow/btn_link_next.svg') no-repeat;
	background-size: cover;
    transform: rotate(270deg);
    transition: all 0.2s;
    cursor: pointer;
}

.applyWrap.paxLOn .arrowWrap i {
    transform: rotate(90deg);
    transition: all 0.2s;
}

.paxFormPanelBody {
/*     margin-left: toRem(215); */
    padding: toRem(30) 0;
    overflow: unset;
}
.applyChkBoxWarp {
	margin-top: toRem(30);
	margin-bottom: toRem(60);
}

.applyChkBoxWarp .chkBox:first-child {
	margin-top: 0;
}
.applyChkBoxWarp .chkBox {
	margin-top: toRem(10);
	display: block;
}
.applyChkbtnWrap {
	justify-content: center;
}
.applyChkbtnWrap .taskButton {
    width: toRem(300);
}

.linkBarLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: toRem(64);
    padding: 0 toRem(30);
    border-radius: toRem(8);
    background-color: $ap-point-skyblue;
}
.url {
    width: toRem(119);
    height: toRem(40);
    padding-left: toRem(20);
    position: relative;
    border-radius: toRem(19);
    background-color: $ap-white;
}

.linkBarLink .url::before {
    content: '';
    width: toRem(16);
    height: toRem(16);
    position: absolute;
    top: toRem(12);
    left: toRem(14);
	background: url('https://com.airpremiacdn.net/resources/onepoint/images/icon/ico_link.svg') no-repeat;
}

.aplyLinkBar {
	margin: toRem(60) 0 toRem(40);
}

.apyNodataCont {
	width: 100%;

}
.apyNodataCont .svgWrap {
    width: toRem(140);
    margin: 0 auto;
}
.apyNodataCont .svgWrap i {
    width: toRem(140);
    height: toRem(140);
    background: url(https://com.airpremiacdn.net/resources/onepoint/images/illust/img_banner_illust_02.png) no-repeat center center;
    background-size: cover;
}
 .apyNodataCont .description {
	padding-top: toRem(20);
	font-weight: 700;
	letter-spacing: toRem(-0.36);
	text-align: center;
}

@include rwd('md'){
	.paxFormPanelBody {
/* 	    margin-left: toRem(120); */
	}
	.applyWrap .slctBox > .psCon {
	    min-width: 100%;
	}
	.applyWrap .slctBox > .slctCon {
		min-width: 100%;
	}
}
@include rwd('sm'){
	.aplyLinkBar {
	    height: auto;
	    padding: toRem(10);
	    flex-direction: column;
	}
	.aplyLinkBar > .linkBWTxt {
		margin-bottom: toRem(10);
	}
	.paxFormPanelBody {
		margin-left: 0;
		padding: toRem(10) 0;
	}

	.applyWrap .slctBox.w130 {
	    min-width: auto;
	}
}
.reserveSum {
	padding: toRem(30) toRem(30) toRem(36);
	border-radius: toRem(8);
	border: toRem(1) solid $ap-line-black;
	background-color: $ap-white;
}
.reserveSum .dayAir {
	padding: toRem(60) 0 toRem(30);
}
.reserveSum .dayAir .title {
	display: flex;
}
.reserveSum .dayAir .trLine {
	margin: toRem(30) 0;
	border: toRem(1) solid $ap-line-gray;
}
.reserveSum .resvVlu {
	margin-top: toRem(10);
	justify-content: flex-start;
}
.reserveSum .resvVlu .grf {
	font-weight: 700;
	color: $ap-text-gray-1;
}
.reserveSum .resvVlu .date,
.reserveSum .resvVlu .aprt {
	font-weight: 700;
}
.reserveSum .arrBar {
	width: toRem(1);
	height: toRem(20);
	margin: 0 toRem(20);
	background: $ap-line-gray;
}


/* 교환권 확인증 */
.changeTicketInfo {
	display: flex;
  	justify-content: center;
}
.changeTicketInfo .changeTicketComment {
	outline : solid toRem(3) black;
	background-color: $ap-gold-1;
	width:toRem(350);
	height:toRem(180);
	padding: toRem(20);
}
.changeTicketInfo .changeTicketComment li {
	text-align:center;
	font-size:toRem(20);
}

.changeTicketInfo .changeTicketComment strong{
	font-weight:bold;
	font-size:toRem(30);
}
@include rwd('sm'){
	.reserveSum .resvVlu .arrBar {
		display: none;
	}
	.reserveSum .resvVlu {
		display: block;
	}
	.reserveSum .resvVlu .grf:last-child {
		margin-top: toRem(10);
	}
	.reserveSum .resvVlu .aprt {
		margin-top: toRem(10);
	}
}

/****************
*** 에러페이지 ***
****************/
.errorWrap {
	text-align: center;
}
.errorWrap .errorImg {
	width: 100%;
	max-width: toRem(240);
	margin-bottom: toRem(30);
}
.errorWrap .errorTit {
	margin-bottom: toRem(30);
	font-size: 3.6rem;
	font-weight: 700;
	line-height: 1.6;
	letter-spacing: -0.05em;
}
.errorWrap .errorMsg {
	margin-bottom: toRem(50);
	color: $ap-text-gray-1;
	line-height: 1.6;
	word-break: keep-all;
}
.errorWrap .errorMsg .msgMT {
	margin-top: toRem(15);
}
.errorWrap .btnWrap {
	width: 100%;
	max-width: toRem(160);
	display: inline-block;
}

@include rwd('sm'){
	.errorWrap .errorImg {
		max-width: toRem(180);
	}
	.errorWrap .errorTit {
		font-size: 2.6rem;
	}
	.errorWrap .errorTit .mBlock {
		display: block;
	}
	.errorWrap .errorMsg {
		font-size: 1.3rem;
	}
	.errorWrap .errorMsg .msgMT {
		margin-top: toRem(10);
	}
}
